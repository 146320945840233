import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import SelectHeads from './SelectHeads';
import SelectStartPlayer from './SelectStartPlayer';
import ReadyButton from './ReadyButton';
import { selectActivePokemon, selectDeckPile } from '../ActiveGame/selectors';

const GameStart = ({ activePokemon, deckPile, userId }) => (
  <Fragment>
    <SelectHeads />
    <SelectStartPlayer />
    <ReadyButton userId={userId} />
    {deckPile && <Fragment>
      {!activePokemon && 'Place Active Pokémon'}
      {activePokemon &&
      'Since Active is placed, you can place Benched Pokémon until you are ready.'}
    </Fragment>}
  </Fragment>
);

GameStart.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  deckPile: PropTypes.instanceOf(Map),
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
    deckPile: selectDeckPile,
  })
)(GameStart);
