import { call, put, select, spawn, takeLatest } from 'redux-saga/effects'
import { GET_GAME, SET_DECK } from './constants';
import {
  setActivePokemon,
  setBenches, setContextMenu,
  setDeckDone,
  setDeckPiles,
  setDiscardPiles,
  setGame,
  setGamePlayers,
  setGameTurn,
  setHands,
  setPrizePiles,
  setUsers,
  setVersion,
} from './actions';
import { selectActiveGame, selectPlayer, selectVersion } from './selectors';
import { get, setDeck } from '../services/game';
import { registerChannels } from '../services/pusher';
import { APP_DEBUG } from '../env';
import { MAIN } from './contextMenuTypes';


export function* handleUpdateGame(initialGame) {

  const activeGame = yield select(selectActiveGame);
  const version = yield select(selectVersion);

  const {
    activePokemon,
    benches,
    discardPiles,
    deckPiles,
    gameTurn,
    gamePlayers,
    hands,
    prizePiles,
    users,
    ...game
  } = initialGame;

  const handleUpdate = function* (entities, entityName, setEntitiesAction) {
    if (!activeGame || activeGame.get('id') !== game.id || version.get(entityName, 0) < game.version) {
      yield put(setVersion({ [entityName]: game.version }));
      yield put(setEntitiesAction(entities));
    } else if (APP_DEBUG) {
      console.log(`SKIPPED UPDATE: ${entityName}`);
    }
  };

  if (APP_DEBUG && activeGame) {
    console.log(`${(new Date()).toISOString()} RECEIVED UPDATE`, activeGame.get('version'), game.version);
  }

  if (activePokemon) {
    yield handleUpdate(activePokemon, 'activePokemon', setActivePokemon);
  }

  if (benches) {
    yield handleUpdate(benches, 'benches', setBenches);
  }

  if (deckPiles) {
    yield handleUpdate(deckPiles, 'deckPiles', setDeckPiles);
  }

  if (discardPiles) {
    yield handleUpdate(discardPiles, 'discardPiles', setDiscardPiles);
  }

  if (gamePlayers) {
    yield handleUpdate(gamePlayers, 'gamePlayers', setGamePlayers);
  }

  if (gameTurn) {
    yield handleUpdate(gameTurn, 'gameTurn', setGameTurn);
  }

  if (hands) {
    yield handleUpdate(hands, 'hands', setHands);
  }

  if (prizePiles) {
    yield handleUpdate(prizePiles, 'prizePiles', setPrizePiles);
  }

  if (users) {
    yield handleUpdate(users, 'users', setUsers);
  }

  yield put(setGame(game));
}

function* getGameTask(action) {
  const game = yield call(get, action.payload.get('id'));

  yield call(handleUpdateGame, game);

  const player = yield select(selectPlayer);

  const channelsSaga = yield call(registerChannels, game.id, player.getIn(['user', 'id']));

  yield put(setContextMenu(MAIN, player.getIn(['user', 'id'])));

  yield spawn(channelsSaga);
}

function* getGameWatcher() {
  yield takeLatest(GET_GAME, getGameTask);
}

function* setDeckTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(setDeck, gameId, action.payload.get('deckId'));

  yield call(handleUpdateGame, game);

  yield put(setDeckDone());
}

function* setDeckWatcher() {
  yield takeLatest(SET_DECK, setDeckTask);
}

export default [
  setDeckWatcher,
  getGameWatcher,
];
