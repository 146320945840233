import { fromJS } from 'immutable';
import { CREATE_GAME, CREATE_GAME_SUCCESS, DELETE_GAME, GET_GAMES, SET_GAMES } from './constants';

export const createGame = (opponentId) => ({
  type: CREATE_GAME,
  payload: fromJS({ opponentId }),
});

export const createGameSuccess = () => ({
  type: CREATE_GAME_SUCCESS,
});

export const deleteGame = (gameId) => ({
  type: DELETE_GAME,
  payload: fromJS({ gameId }),
});

export const getGames = () => ({
  type: GET_GAMES,
});

export const setGames = (games) => ({
  type: SET_GAMES,
  payload: fromJS({ games }),
});