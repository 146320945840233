export const START_BENCH_FLOW = 'POKEMON/START_BENCH_FLOW';
export const START_BENCH = 'POKEMON/START_BENCH';
export const BENCH = 'POKEMON/BENCH';
export const BENCH_CANCEL = 'POKEMON/BENCH_CANCEL';
export const END_BENCH = 'POKEMON/END_BENCH';

export const START_ATTACH_FLOW = 'POKEMON/START_ATTACH_FLOW';
export const START_ATTACH = 'POKEMON/START_ATTACH';
export const ATTACH = 'POKEMON/ATTACH';
export const ATTACH_CANCEL = 'POKEMON/ATTACH_CANCEL';
export const END_ATTACH = 'POKEMON/END_ATTACH';

export const PUT_ACTIVE_POKEMON = 'POKEMON/PUT_ACTIVE_POKEMON';
export const END_PUT_BENCH_AS_ACTIVE_POKEMON = 'POKEMON/END_PUT_BENCH_AS_ACTIVE_POKEMON';

export const SET_ACTIVE_BENCH = 'POKEMON/SET_ACTIVE_BENCH';
export const UNSET_ACTIVE_BENCH = 'POKEMON/UNSET_ACTIVE_BENCH';
