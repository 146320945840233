import { fromJS } from 'immutable';
import { CHOOSE_BENCH_POSITION, RETREAT, RETREAT_DONE, RETREAT_START } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  retreatFlowing: false,
  retreating: false,
  choosingEnergies: false,
  choosingBenchPosition: false,
  energyAttachmentIds: [],
  benchPosition: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case RETREAT_START:
      return state.set('retreatFlowing', true)
        .set('choosingEnergies', true)
        .set('choosingBenchPosition', false);

    case CHOOSE_BENCH_POSITION:
      return state.set('retreatFlowing', true)
        .set('choosingEnergies', false)
        .set('energyAttachmentIds', action.payload.get('energyAttachmentIds'))
        .set('choosingBenchPosition', true);

    case RETREAT:
      return state.set('retreating', true)
        .set('benchPosition', action.payload.get('benchPosition'));

    case RETREAT_DONE:
      return initialState;

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
