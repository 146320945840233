import { fromJS } from 'immutable';
import {
  GET_GAME,
  GO_BACK,
  SET_ACTIVE_POKEMON,
  SET_BENCHES,
  SET_CONTEXT_MENU,
  SET_DECK,
  SET_DECK_DONE,
  SET_DECK_PILES,
  SET_DISCARD_PILES,
  SET_GAME,
  SET_GAME_PLAYERS,
  SET_GAME_TURN,
  SET_HANDS,
  SET_PRIZE_PILES,
  SET_USERS,
  SET_VERSION,
} from './constants';

export const setContextMenu = (menu, userId) => ({
  type: SET_CONTEXT_MENU,
  payload: fromJS({ menu, userId }),
});

export const goBack = () => ({
  type: GO_BACK,
});

export const setDeck = (deckId) => ({
  type: SET_DECK,
  payload: fromJS({ deckId }),
});

export const setDeckDone = () => ({
  type: SET_DECK_DONE,
});

export const setVersion = (version) => ({
  type: SET_VERSION,
  payload: fromJS({ version }),
});

export const getGame = (id) => ({
  type: GET_GAME,
  payload: fromJS({ id }),
});

export const setGame = (activeGame) => ({
  type: SET_GAME,
  payload: fromJS({ activeGame }),
});

export const setGamePlayers = (gamePlayers) => ({
  type: SET_GAME_PLAYERS,
  payload: fromJS({ gamePlayers }),
});

export const setGameTurn = (gameTurn) => ({
  type: SET_GAME_TURN,
  payload: fromJS({ gameTurn }),
});

export const setActivePokemon = (activePokemon) => ({
  type: SET_ACTIVE_POKEMON,
  payload: fromJS({ activePokemon }),
});

export const setBenches = (benches) => ({
  type: SET_BENCHES,
  payload: fromJS({ benches }),
});

export const setDiscardPiles = (discardPiles) => ({
  type: SET_DISCARD_PILES,
  payload: fromJS({ discardPiles }),
});

export const setDeckPiles = (deckPiles) => ({
  type: SET_DECK_PILES,
  payload: fromJS({ deckPiles }),
});

export const setHands = (hands) => ({
  type: SET_HANDS,
  payload: fromJS({ hands }),
});

export const setPrizePiles = (prizePiles) => ({
  type: SET_PRIZE_PILES,
  payload: fromJS({ prizePiles }),
});

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: fromJS({ users }),
});
