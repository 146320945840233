import axios from 'axios';
import { API_URL } from '../env';

async function all() {
  const response = await axios.get(`${API_URL}/decks`);

  return response.data.data;
}

export {
  all,
};
