import axios from 'axios';
import { API_URL } from '../env';
import { normalize } from '../services/helpers';

const gameStart = {
  flipStartCoin: async (id, heads) => {
    const response = await axios.patch(`${API_URL}/games/${id}/flip-start-coin`, {
      heads,
    });

    return normalize(response.data);
  },
  setFirstPlayer: async (id, firstPlayerId) => {
    const response = await axios.patch(`${API_URL}/games/${id}/set-first-player`, {
      first_player_id: firstPlayerId,
    });

    return normalize(response.data);
  },
  setReady: async (id) => {
    const response = await axios.patch(`${API_URL}/games/${id}/set-ready`);

    return normalize(response.data);
  },
  putActivePokemon: async (id, handPosition) => {
    const response = await axios.patch(`${API_URL}/games/${id}/put-active-pokemon`, {
      hand_position: handPosition,
    });

    return normalize(response.data);
  },
};

export default gameStart;
