import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { setReady } from './actions';
import { selectSettingReady } from './selectors';
import ConfirmButton from '../ConfirmButton';
import { selectActiveGame, selectActivePokemon, selectGamePlayer } from '../ActiveGame/selectors';

const ReadyButton = ({ activePokemon, game, gamePlayer, setReadyAction, settingReady, userId }) => {

  const playerChoosesWhoStarts = game.get('start_chooser_id') === userId;

  const showReady = game.get('initial_coin_flip') !== null && (
    (playerChoosesWhoStarts && game.get('first_player_id')) || !playerChoosesWhoStarts
  ) && activePokemon;

  if (gamePlayer.get('ready') || !showReady) {
    return 'Ready';
  }

  return (
    <ConfirmButton onConfirm={setReadyAction} disabled={settingReady}>
      Ready
    </ConfirmButton>
  );
};

ReadyButton.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  game: PropTypes.instanceOf(Map),
  gamePlayer: PropTypes.instanceOf(Map),
  setReadyAction: PropTypes.func,
  settingReady: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
    game: selectActiveGame,
    gamePlayer: selectGamePlayer,
    settingReady: selectSettingReady,
  }),
  {
    setReadyAction: setReady,
  }
)(ReadyButton);