import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { selectHand } from '../ActiveGame/selectors';
import { HAND } from '../ActiveGame/contextMenuTypes';
import Pile from '../Pile';
import HandMenu from './HandMenu';
import ContextMenu from '../ContextMenu';

const Hand = ({ hand, userId }) => (
  <ContextMenu menu={HAND} userId={userId}>
    <Pile
      cardsOrCardables={hand.get('cards')}
      title="Hand"
      menu={<HandMenu userId={userId} />}
    />
  </ContextMenu>
);

Hand.propTypes = {
  userId: PropTypes.number, // passed in
  hand: PropTypes.instanceOf(Map),
};

export default connect(
  createStructuredSelector({
    hand: selectHand,
  })
)(Hand);
