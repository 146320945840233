import { createSelector } from 'reselect';
import { selectGameTurn, selectIsActivePlayer } from '../ActiveGame/selectors';

const selectDomain = (state) => state.attackButton;

export const selectAttackFlowing = createSelector(
  [selectDomain], (state) => state.get('attackFlowing'),
);

export const selectAttacking = createSelector(
  [selectDomain], (state) => state.get('attacking'),
);

export const selectCanAttack = createSelector(
  [selectIsActivePlayer, selectGameTurn],
  (isActivePlayer, gameTurn) => isActivePlayer && gameTurn.get('drew') && !gameTurn.get('attack_started')
);
