import axios from 'axios';
import { API_URL } from '../env';
import { normalize } from '../services/helpers';

export const create = async (opponentId) => {
  await axios.post(`${API_URL}/games`, {
    opponent_id: opponentId,
  });
};

export const destroy = async (gameId) => {
  await axios.delete(`${API_URL}/games/${gameId}`);
};

export const all = async () => {
  const response = await axios.get(`${API_URL}/games`);

  return response.data.data;
};

export const get = async (id) => {
  const response = await axios.get(`${API_URL}/games/${id}`, {
    params: {
      include: [
        'activePokemon',
        'benches',
        'discardPiles',
        'deckPiles',
        'gameTurn',
        'gamePlayers',
        'hands',
        'prizePiles',
        'users',
      ],
    }
  });

  return normalize(response.data);
};

export const setDeck = async (id, deckId) => {
  const response = await axios.patch(`${API_URL}/games/${id}/set-deck`, {
    deck_id: deckId,
  });

  return normalize(response.data);
};

export const pass = async (id) => {
  const response = await axios.patch(`${API_URL}/games/${id}/pass`);

  return normalize(response.data);
};

export const retreat = async (id, benchPosition, energyAttachmentIds) => {
  const response = await axios.patch(`${API_URL}/games/${id}/retreat`, {
    bench_position: benchPosition,
    energyAttachmentIds,
  });

  return normalize(response.data);
};
