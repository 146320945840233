import { fromJS } from 'immutable';
import { UNSET_CARD, SET_CARD } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  card: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_CARD:
      return state.set('card', action.payload.get('card'));

    case UNSET_CARD:
      return state.set('card', initialState.get('card'));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
