import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';
import { createGame } from './actions';
import { getUsers } from '../Users/actions';
import { selectUsers, selectUsersLoaded } from '../Users/selectors';
import { selectCreating } from './selectors';

const CreateGame = ({ createGameAction, users, usersLoaded, creating, getUsersAction }) => {
  const [opponentId, changeOpponentId] = useState('');

  useEffect(() => {
    if (users.size) {
      changeOpponentId(users.first().get('id'));
    }
  }, [users]);

  useEffect(() => {
    getUsersAction();
  }, [getUsersAction]);

  if (!usersLoaded) {
    return 'Loading create game form...';
  }

  if (creating) {
    return 'Creating game...';
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createGameAction(opponentId);
  };

  return (
    <form onSubmit={handleSubmit}>
      <select name="" id="" onChange={(e) => changeOpponentId(e.target.value)} value={opponentId}>
        {users.map((user) => (
          <option key={user.get('id')} value={user.get('id')}>{user.get('name')}</option>
        ))}
      </select>
      <button>Create</button>
    </form>
  );
};

CreateGame.propTypes = {
  createGameAction: PropTypes.func.isRequired,
  getUsersAction: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(List).isRequired,
  usersLoaded: PropTypes.bool,
  creating: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    users: selectUsers,
    usersLoaded: selectUsersLoaded,
    creating: selectCreating,
  }), {
    createGameAction: createGame,
    getUsersAction: getUsers,
  }
)(CreateGame);
