import React from 'react';
import SelectPile from '../SelectPile';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  selectCanRetreat,
  selectChoosingEnergies,
  selectEnergyAttachmentIds,
  selectRetreatFlowing,
  selectRetreating
} from './selectors';
import { chooseBenchPosition, retreat, retreatDone } from './actions';
import ContextMenu from '../ContextMenu';
import { selectActivePokemon } from '../ActiveGame/selectors';
import { RETREAT } from '../ActiveGame/contextMenuTypes';

const RetreatContextMenu = (
  {
    activePokemon,
    canRetreat,
    chooseBenchPositionAction,
    choosingEnergies,
    energyAttachmentIds,
    retreating,
    retreatDoneAction,
    userId,
  }
) => {

  if (!canRetreat || retreating || !activePokemon) {
    return null;
  }

  const count = activePokemon.getIn(['card', 'retreat_cost']);

  const energyAttachments = activePokemon.get('energyAttachments');

  return (
    <ContextMenu menu={RETREAT} userId={userId}>
      {choosingEnergies && energyAttachments.size >= count && <SelectPile
        grid
        title="Energies"
        onClose={retreatDoneAction}
        cardsOrCardables={energyAttachments}
        message={`Select ${count === 1 ? 'an energy' : `${count} energies`} to discard.`}
        selectCount={count}
        selectedIds={energyAttachmentIds}
        onSelectDone={chooseBenchPositionAction}  // when selected equals retreat_cost, turn off selectingEnergies, turn on selectingBenchPokemon
      />}
    </ContextMenu>
  )
};

RetreatContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  canRetreat: PropTypes.bool.isRequired,
  retreating: PropTypes.bool.isRequired,
  retreatAction: PropTypes.func.isRequired,
  retreatDoneAction: PropTypes.func.isRequired,
  energyAttachmentIds: PropTypes.instanceOf(List),
  chooseBenchPositionAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
    canRetreat: selectCanRetreat,
    retreating: selectRetreating,
    retreatFlowing: selectRetreatFlowing,
    energyAttachmentIds: selectEnergyAttachmentIds,
    choosingEnergies: selectChoosingEnergies,
  }),
  {
    retreatAction: retreat,
    retreatDoneAction: retreatDone,
    chooseBenchPositionAction: chooseBenchPosition,
  }
)(RetreatContextMenu);
