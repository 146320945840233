import { fromJS } from 'immutable';
import { SET_USERS } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  users: [],
  usersLoaded: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERS:
      return state.set('users', action.payload.get('users'))
        .set('usersLoaded', true);

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
