import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Root } from './styled';
import { MAIN } from '../ActiveGame/contextMenuTypes';
import { goBack } from '../ActiveGame/actions';
import { selectActiveContextMenu, selectActiveContextUserId } from '../ActiveGame/selectors';

const ContextMenu = ({ userId, menu, children, goBackAction, activeContextMenu, activeContextUserId }) => (
  <Root userId={userId} menu={menu} activeContextMenu={activeContextMenu} activeContextUserId={activeContextUserId}>
    {menu !== MAIN && <button onClick={goBackAction}>Go back</button>}
    {children}
  </Root>
);

ContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  menu: PropTypes.string.isRequired, // passed in
  children: PropTypes.node.isRequired, // passed in
  activeContextMenu: PropTypes.string,
  activeContextUserId: PropTypes.number,
};

export default connect(
  createStructuredSelector({
    activeContextMenu: selectActiveContextMenu,
    activeContextUserId: selectActiveContextUserId,
  }), {
    goBackAction: goBack,
  }
)(ContextMenu);
