import { fromJS } from 'immutable';
import {
  GO_BACK,
  SET_ACTIVE_POKEMON,
  SET_BENCHES, SET_CONTEXT_MENU,
  SET_DECK,
  SET_DECK_DONE,
  SET_DECK_PILES,
  SET_DISCARD_PILES,
  SET_GAME,
  SET_GAME_PLAYERS,
  SET_GAME_TURN,
  SET_HANDS,
  SET_PRIZE_PILES,
  SET_USERS,
  SET_VERSION,
} from './constants';
import { APP_RESET } from '../App/constants';
import { MAIN } from './contextMenuTypes';

const initialState = fromJS({
  contextMenuStack: [],
  activeContextMenu: MAIN,
  activeContextUserId: null,
  settingDeck: false,
  activeGame: null,
  version: {},
  activePokemon: [],
  benches: [],
  discardPiles: [],
  deckPiles: [],
  gameTurn: null,
  gamePlayers: [],
  hands: [],
  prizePiles: [],
  users: [],
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_CONTEXT_MENU:
      return state.set('activeContextMenu', action.payload.get('menu'))
        .set(
          'contextMenuStack',
          // only update if the menu clicked is not the last one
          state.get('contextMenuStack').last() === action.payload.get('menu') ?
            state.get('contextMenuStack') :
            state.get('contextMenuStack').push(action.payload.get('menu'))
        )
        .set('activeContextUserId', action.payload.get('userId'));

    case GO_BACK:
      return state.set('activeContextMenu', state.get('contextMenuStack').get(-2) || MAIN) // set active to second to last
        .set('contextMenuStack', state.get('contextMenuStack').pop()); // remove the last element

    case SET_DECK:
      return state.set('settingDeck', true);

    case SET_DECK_DONE:
      return state.set('settingDeck', initialState.get('settingDeck'));

    case SET_GAME:
      return state.set('activeGame', action.payload.get('activeGame'));

    case SET_VERSION:
      return state.set('version', state.get('version').merge(action.payload.get('version')));

    case SET_ACTIVE_POKEMON:
      return state.set('activePokemon', action.payload.get('activePokemon'));

    case SET_BENCHES:
      return state.set('benches', action.payload.get('benches'));

    case SET_DISCARD_PILES:
      return state.set('discardPiles', action.payload.get('discardPiles'));

    case SET_DECK_PILES:
      return state.set('deckPiles', action.payload.get('deckPiles'));

    case SET_GAME_PLAYERS:
      return state.set('gamePlayers', action.payload.get('gamePlayers'));

    case SET_GAME_TURN:
      return state.set('gameTurn', action.payload.get('gameTurn'));

    case SET_HANDS:
      return state.set('hands', action.payload.get('hands'));

    case SET_PRIZE_PILES:
      return state.set('prizePiles', action.payload.get('prizePiles'));

    case SET_USERS:
      return state.set('users', action.payload.get('users'));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
