import styled from 'styled-components/macro';
import { connectedStyle } from '../App/styled';

export const Root = connectedStyle(styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.windowWidth * .2}px;
  position: absolute;
  left: ${props => props.windowWidth * .050}px;
  bottom: ${props => props.windowHeight * .015}px;

  & > div {
    position: absolute;
    
    &:nth-child(1), &:nth-child(2) {
      bottom: ${props => props.windowHeight * .283}px;
    }
    
    &:nth-child(3), &:nth-child(4) {
      bottom: ${props => props.windowHeight * .141}px;
    }
    
    &:nth-child(5), &:nth-child(6) {
      bottom: 0;
    }
    
    &:nth-child(even) {
      left: ${props => props.windowWidth * .108}px;
    }
  }
`);
