import axios from 'axios';
import { API_URL } from '../env';
import { normalize } from '../services/helpers';

const gameTurn = {
  draw: async (id) => {
    const response = await axios.patch(`${API_URL}/games/${id}/draw`);

    return normalize(response.data);
  },
  attach: async (id, energyPosition, benchPosition) => {
    const response = await axios.patch(`${API_URL}/games/${id}/attach`, {
      type: 'ACTIVE_POKEMON',
      energy_position: energyPosition,
      bench_position: benchPosition,
    });

    return normalize(response.data);
  },
  bench: async (id, handPosition, benchPosition) => {
    const response = await axios.patch(`${API_URL}/games/${id}/bench`, {
      hand_position: handPosition,
      bench_position: benchPosition,
    });

    return normalize(response.data);
  },
  putBenchAsActivePokemon: async (id, benchPosition) => {
    const response = await axios.patch(`${API_URL}/games/${id}/put-bench-as-active-pokemon`, {
      bench_position: benchPosition,
    });

    return normalize(response.data);
  },
  drawPrize: async (id, prizePosition) => {
    const response = await axios.patch(`${API_URL}/games/${id}/draw-prize`, {
      prize_position: prizePosition,
    });

    return normalize(response.data);
  },
};

export default gameTurn;
