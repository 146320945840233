import { fromJS } from 'immutable';
import { CONFIRM } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  confirmed: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case CONFIRM:
      return state.set('confirmed', action.payload.get('symbol'));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
