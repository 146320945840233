import { call, put, select, takeLatest } from 'redux-saga/effects'
import { DRAW } from './constants';
import { endDraw, } from './actions';
import gameTurnService from '../services/gameTurn';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { selectActiveGame, selectPlayer } from '../ActiveGame/selectors';
import { setContextMenu } from '../ActiveGame/actions';
import { HAND } from '../ActiveGame/contextMenuTypes';

function* drawTask() {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameTurnService.draw, gameId);

  yield call(handleUpdateGame, game);

  yield put(endDraw());

  const playerId = (yield select(selectPlayer)).getIn(['user', 'id']);
  yield put(setContextMenu(HAND, playerId));
}

function* drawWatcher() {
  yield takeLatest(DRAW, drawTask);
}

export default [
  drawWatcher,
];
