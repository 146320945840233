import { call, put, select, takeLatest } from 'redux-saga/effects'
import { PASS } from './constants';
import { passDone, } from './actions';
import { pass } from '../services/game';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { selectActiveGame } from '../ActiveGame/selectors';

function* passTask() {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(pass, gameId);

  yield call(handleUpdateGame, game);

  yield put(passDone());
}

function* passWatcher() {
  yield takeLatest(PASS, passTask);
}

export default [
  passWatcher,
];
