import { fromJS } from 'immutable';
import {
  GET_CURRENT_USER,
  LOGIN,
  LOGIN_FAILURE,
  LOGIN_RUNNING,
  LOGIN_SUCCESS,
  LOGOUT,
} from './constants';

export const getCurrentUser = () => ({
  type: GET_CURRENT_USER,
});

export const login = (email, password) => ({
  type: LOGIN,
  payload: fromJS({ email, password }),
});

/* An action to manually set `loggingIn` true */
export const loginRunning = () => ({
  type: LOGIN_RUNNING,
});


export const loginSuccess = (user, accessToken) => ({
  type: LOGIN_SUCCESS,
  payload: fromJS({ user, accessToken }),
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: fromJS({ error }),
});

export const logout = () => ({
  type: LOGOUT,
});
