import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { Root } from './styled';
import { selectDiscardPile } from '../ActiveGame/selectors';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { DISCARD } from '../ActiveGame/contextMenuTypes';
import { setContextMenu } from '../ActiveGame/actions';

const DiscardPile = ({ discardPile, setContextMenuAction, userId }) => {
  const firstCard = discardPile.get('cards').sortBy(card => card.get('order')).last();
  return (
    <Root onClick={() => setContextMenuAction(DISCARD, userId)}>
      {firstCard && <Image image={cardImageUrl(firstCard)} />}
    </Root>
  );
};

DiscardPile.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  discardPile: PropTypes.instanceOf(Map),
  setContextMenuAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    discardPile: selectDiscardPile,
  }), {
    setContextMenuAction: setContextMenu,
  }
)(DiscardPile);
