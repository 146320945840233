import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List } from 'immutable';
import GridView from './GridView';
import SliderView from './SliderView';
import { StyledPile } from './styled';

const normalizeCardsOrCardables = (cardsOrCardables) => {
  const cards = cardsOrCardables.map(cardOrCardable => {
    const card = cardOrCardable.get('card') || cardOrCardable;
    return fromJS({
      ...card.toObject(),
      pivot_id: card.get('pivot_id') || cardOrCardable.get('id'),
    });
  });

  return fromJS(cards).sort((a, b) => a.get('order') - b.get('order'));
};

const Pile = (
  {
    title,
    cardsOrCardables,
    grid,
    slider,
    getDim,
    getSelectButton,
    selectDoneButton,
    selectionFeedbackMessage,
    message,
    menu,
  }
) => {
  const [viewingGrid, toggleViewGrid] = useState(grid || (slider ? false : false));
  const [viewingCard, viewCard] = useState(false);
  const [selectedCardIndex, selectCard] = useState(0);

  useEffect(() => {
    selectCard(0);
  }, [cardsOrCardables]);

  const cards = normalizeCardsOrCardables(cardsOrCardables);

  const viewProps = { cards, getDim, getSelectButton, activeCardIndex: selectedCardIndex, onChange: selectCard };

  const card = cards.get(selectedCardIndex);

  return (
    <StyledPile>
      <h3>{title}</h3>
      {message && <p>{message}</p>}
      <div>
        {card && card.get('name')}
        <button onClick={() => toggleViewGrid(!viewingGrid)}>Toggle View Grid</button>
        {selectDoneButton}
        {selectionFeedbackMessage}
        {card && !viewingGrid && <button onClick={() => viewCard(!viewingCard)}>
          {viewingCard ? 'Hide' : 'View'} Card
        </button>}
        {card && menu && React.cloneElement(menu, { card })}
      </div>
      <div>
        <div style={{ display: viewingGrid ? 'block' : 'none' }}>
          <GridView {...viewProps} />
        </div>
        <div style={{ display: !viewingGrid ? 'block' : 'none' }}>
          <SliderView {...viewProps} viewingCard={viewingCard} />
        </div>
      </div>
    </StyledPile>
  );
};

Pile.propTypes = {
  cardsOrCardables: PropTypes.instanceOf(List).isRequired,
  title: PropTypes.string.isRequired,
  grid: PropTypes.bool,
  slider: PropTypes.bool,
  message: PropTypes.string,
  menu: PropTypes.node,

  selectionFeedbackMessage: PropTypes.string, // only use this via SelectPile
  getDim: PropTypes.func, // only use this via SelectPile
  getSelectButton: PropTypes.func, // only use this via SelectPile
  selectDoneButton: PropTypes.node, // only use this via SelectPile
};

export default Pile;