import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { BASIC_POKEMON, ENERGY, TRAINER } from '../constants';
import {
  selectActiveGame,
  selectActivePokemon,
  selectBench,
  selectGameTurn,
  selectUser
} from '../ActiveGame/selectors';
import { putActivePokemon } from '../GameStart/actions';
import { startAttachFlow, startBenchFlow } from '../Pokemon/actions';
import ConfirmButton from '../ConfirmButton';
import { selectAttachFlowing, selectBenchFlowing } from '../Pokemon/selectors';
import { selectPuttingActive } from '../GameStart/selectors';

const HandMenu = (
  {
    activePokemon,
    attachFlowing,
    bench,
    benchFlowing,
    card,
    game,
    gameTurn,
    putActivePokemonAction,
    puttingActive,
    startAttachFlowAction,
    startBenchFlowAction,
    user,
  }
) => {

  const cardTypes = card.getIn(['cardTypes']).map(cardType => cardType.get('id')).toArray();

  const activePlayerId = game.get('active_player_id');

  const isActivePlayer = activePlayerId === user.get('id');

  const activeAndDrew = isActivePlayer && gameTurn.get('drew');

  return (
    <Fragment>
      {/*Start Actions*/}
      {cardTypes.includes(BASIC_POKEMON) && !activePlayerId && !activePokemon && <ConfirmButton
        onConfirm={() => putActivePokemonAction(card.get('order'))}
        disabled={puttingActive}
      >
        Place as Active Pokémon
      </ConfirmButton>}

      {/*Active Actions*/}
      {activeAndDrew && cardTypes.includes(TRAINER) && <li>Play Trainer</li>}
      {activeAndDrew && cardTypes.includes(ENERGY) && !gameTurn.get('attached') && <button
        onClick={() => startAttachFlowAction(card.get('order'))}
        disabled={attachFlowing}
      >
        Attach
      </button>}
      {bench.get('cards').size < 5 && (activeAndDrew || !activePlayerId) && activePokemon && cardTypes.includes(BASIC_POKEMON) &&
      <button
        onClick={() => startBenchFlowAction(card.get('order'))}
        disabled={benchFlowing}
      >
        Place on Bench
      </button>}
    </Fragment>
  );
};

HandMenu.propTypes = {
  card: PropTypes.instanceOf(Map).isRequired, // passed in
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  bench: PropTypes.instanceOf(Map),
  game: PropTypes.instanceOf(Map).isRequired,
  gameTurn: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(Map),
  attachFlowing: PropTypes.bool.isRequired,
  puttingActive: PropTypes.bool.isRequired,
  benchFlowing: PropTypes.bool.isRequired,
  putActivePokemonAction: PropTypes.func.isRequired,
  startAttachFlowAction: PropTypes.func.isRequired,
  startBenchFlowAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
    attachFlowing: selectAttachFlowing,
    bench: selectBench,
    benchFlowing: selectBenchFlowing,
    game: selectActiveGame,
    gameTurn: selectGameTurn,
    puttingActive: selectPuttingActive,
    user: selectUser,
  }),
  {
    putActivePokemonAction: putActivePokemon,
    startAttachFlowAction: startAttachFlow,
    startBenchFlowAction: startBenchFlow,
  }
)(HandMenu);
