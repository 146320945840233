import { PASS, PASS_DONE, } from './constants';

export const pass = () => ({
  type: PASS,
});

export const passDone = () => ({
  type: PASS_DONE,
});

