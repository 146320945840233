import Echo from 'laravel-echo';
import { END, eventChannel } from 'redux-saga'
import { call, take } from 'redux-saga/effects'
import cookieManager from './cookieManager';
import history from '../history';
import { APP_DEBUG, APP_URL, PUSHER_KEY } from '../env';
import { normalize } from '../services/helpers';
import { handleUpdateGame } from '../ActiveGame/sagas';

function registerChannels(gameId, userId) {
  return function* () {
    const echo = yield call(setupPusher);

    const channel = eventChannel(emitter => {

      function handleGameUpdate({ game: gameResponse }) {
        const game = normalize(gameResponse);

        emitter(game);
      }

      // if current user is not in game {
      // echo.private(`game.${gameId}`)
      //   .listen('GameUpdate', handleGameUpdate);
      // }

      echo.private(`game.${gameId}.user.${userId}`)
        .listen('GameUpdate', handleGameUpdate);

      history.listen(() => emitter(END));

      return closePusher;
    });

    while (true) {
      const game = yield take(channel);
      yield call(handleUpdateGame, game);
    }
  };
}

function setupPusher() {

  const accessToken = cookieManager.getAccessToken();

  window.Pusher = require('pusher-js');

  window.Pusher.logToConsole = APP_DEBUG;

  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: PUSHER_KEY,
    cluster: 'us2',
    encrypted: true,
    logToConsole: APP_DEBUG,
    authEndpoint: `${APP_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });

  return window.Echo;
}

function closePusher() {
  window.Echo.disconnect();
}

export {
  registerChannels,
  setupPusher,
};
