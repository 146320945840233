import { fromJS } from 'immutable';
import {
  PASS,
  PASS_DONE,
} from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  passing: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case PASS:
      return state.set('passing', true);

    case PASS_DONE:
      return state.set('passing', initialState.get('passing'));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
