import { call, put, spawn, takeLatest } from 'redux-saga/effects'
import { APP_SETUP, SETUP_RESIZE } from './constants';
import { getCurrentUser } from '../Login/actions';
import { registerResizing } from '../services/resize';
import { setSize } from './actions';

function* appSetupTask() {
  yield put(getCurrentUser())
}

function* appSetupWatcher() {
  yield takeLatest(APP_SETUP, appSetupTask);
}

function* setupResizeTask() {
  yield put(setSize(window.innerWidth, window.innerHeight));

  const resizeSaga = yield call(registerResizing);

  yield spawn(resizeSaga);
}

function* setupResizeWatcher() {
  yield takeLatest(SETUP_RESIZE, setupResizeTask);
}

export default [
  appSetupWatcher,
  setupResizeWatcher,
];
