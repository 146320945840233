import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { selectGameTurn, selectIsActivePlayer } from '../ActiveGame/selectors';
import { selectDrawing } from '../Deck/selectors';
import { draw } from '../Deck/actions';
import ConfirmButton from '../ConfirmButton';

const DrawButton = ({ drawAction, gameTurn, isActivePlayer, drawing }) => {

  if (!isActivePlayer || gameTurn.get('drew')) {
    return null;
  }

  return <ConfirmButton onConfirm={drawAction} disabled={drawing}>Draw</ConfirmButton>;
};

DrawButton.propTypes = {
  userId: PropTypes.number.isRequired, // passed in

  drawing: PropTypes.bool.isRequired,
  gameTurn: PropTypes.instanceOf(Map).isRequired,
  isActivePlayer: PropTypes.bool.isRequired,

  drawAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    drawing: selectDrawing,
    gameTurn: selectGameTurn,
    isActivePlayer: selectIsActivePlayer,
  }), {
    drawAction: draw,
  }
)(DrawButton);
