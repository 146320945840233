import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_USERS } from './constants';
import { setUsers } from './actions';
import { all } from '../services/user';

function* getUsersTask() {
  const users = yield call(all);
  yield put(setUsers(users));
}

function* getUsersWatcher() {
  yield takeLatest(GET_USERS, getUsersTask);
}

export default [
  getUsersWatcher,
];
