import { fromJS } from 'immutable';
import {
  END_FLIP_COIN,
  END_PUT_ACTIVE,
  END_SET_FIRST_PLAYER,
  END_SET_READY,
  FLIP_START_COIN,
  PUT_ACTIVE_POKEMON,
  SET_FIRST_PLAYER,
  SET_READY,
} from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  settingReady: false,
  puttingActive: false,
  flippingCoin: false,
  settingFirstPlayer: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case SET_READY:
      return state.set('settingReady', true);

    case END_SET_READY:
      return state.set('settingReady', false);

    case SET_FIRST_PLAYER:
      return state.set('settingFirstPlayer', true);

    case END_SET_FIRST_PLAYER:
      return state.set('settingFirstPlayer', false);

    case FLIP_START_COIN:
      return state.set('flippingCoin', true);

    case END_FLIP_COIN:
      return state.set('flippingCoin', false);

    case PUT_ACTIVE_POKEMON:
      return state.set('puttingActive', true);

    case END_PUT_ACTIVE:
      return state.set('puttingActive', false);

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
