import { createSelector } from 'reselect';

const selectDomain = (state) => state.prizes;

export const selectDrawing = createSelector(
  [selectDomain], (state) => state.get('drawing'),
);

export const selectActivePrize = createSelector(
  [selectDomain], (state) => state.get('activePrize'),
);
