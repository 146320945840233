import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { Root } from './styled';
import { addToBench, addToBenchCancel, attach, attachCancel, setActiveBenchPosition } from '../Pokemon/actions';
import {
  selectActiveBenchPosition,
  selectAttachFlowing,
  selectAttaching,
  selectBenchFlowing,
  selectBenching,
  selectPuttingActive,
} from '../Pokemon/selectors';
import { selectBench, selectGameIsStarted, selectIsActivePlayer } from '../ActiveGame/selectors';
import { retreat, retreatDone } from '../RetreatButton/actions';
import ConfirmButton from '../ConfirmButton';
import ActionableButton from '../ActionableButton';
import { selectChoosingBenchPosition, selectRetreating } from '../RetreatButton/selectors';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { BENCH } from '../ActiveGame/contextMenuTypes';
import { setContextMenu } from '../ActiveGame/actions';

const Bench = (
  {
    addToBenchAction,
    addToBenchCancelAction,
    attachAction,
    attachCancelAction,
    attachFlowing,
    attaching,
    bench,
    benchFlowing,
    benching,
    choosingBenchPosition,
    gameIsStarted,
    isActivePlayer,
    retreating,
    retreatAction,
    retreatDoneAction,
    setActiveBenchPositionAction,
    setContextMenuAction,
    userId,
  }) => {

  const benchPositions = [];

  let benchAction = (position) => {
    setActiveBenchPositionAction(position);
    setContextMenuAction(BENCH, userId);
  };
  let onBenchCancel = null;
  let disabled = null;

  if (isActivePlayer && attachFlowing) {
    benchAction = attachAction;
    onBenchCancel = attachCancelAction;
    disabled = attaching;
  }

  if (isActivePlayer && choosingBenchPosition) {
    benchAction = retreatAction;
    onBenchCancel = retreatDoneAction;
    disabled = retreating;
  }

  for (let i = 0; i < 5; i++) {
    const benchCard = bench.get('cards').find(card => card.get('order') === i);

    let benchPosition = '';

    if (benchCard) {
      benchPosition = (
        <ActionableButton
          onClick={() => benchAction(i)}
          disabled={disabled}
          onCancel={onBenchCancel}
          plain
        >
          <Image image={cardImageUrl(benchCard)} />
        </ActionableButton>
      );
    }

    if (
      (!gameIsStarted || isActivePlayer) && !benchCard &&
      (benchFlowing || choosingBenchPosition)
    ) {
      benchPosition = (
        <ConfirmButton
          onConfirm={() => benchFlowing ? addToBenchAction(i) : benchAction(i)}
          onCancel={benchFlowing ? addToBenchCancelAction : onBenchCancel}
          disabled={benchFlowing ? benching : disabled}
        >
          Place
        </ConfirmButton>
      );
    }

    benchPositions.push(<li key={i}>{benchPosition}</li>)
  }

  return <Root>{benchPositions}</Root>;
};

Bench.propTypes = {
  userId: PropTypes.number.isRequired, // Passed in
  addToBenchCancelAction: PropTypes.func,
  addToBenchAction: PropTypes.func,
  attachAction: PropTypes.func,
  attachCancelAction: PropTypes.func,
  attachFlowing: PropTypes.bool,
  attaching: PropTypes.bool,
  retreatAction: PropTypes.func,
  retreatDoneAction: PropTypes.func,
  bench: PropTypes.instanceOf(Map),
  benchFlowing: PropTypes.bool,
  benching: PropTypes.bool,
  gameIsStarted: PropTypes.bool,
  isActivePlayer: PropTypes.bool,
  choosingBenchPosition: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    attachFlowing: selectAttachFlowing,
    activeBenchPosition: selectActiveBenchPosition,
    attaching: selectAttaching,
    bench: selectBench,
    benchFlowing: selectBenchFlowing,
    benching: selectBenching,
    gameIsStarted: selectGameIsStarted,
    isActivePlayer: selectIsActivePlayer,
    retreating: selectRetreating,
    puttingActive: selectPuttingActive,
    choosingBenchPosition: selectChoosingBenchPosition,
  }),
  {
    addToBenchAction: addToBench,
    addToBenchCancelAction: addToBenchCancel,
    attachAction: attach,
    attachCancelAction: attachCancel,
    retreatAction: retreat,
    retreatDoneAction: retreatDone,
    setActiveBenchPositionAction: setActiveBenchPosition,
    setContextMenuAction: setContextMenu,
  }
)(Bench);
