export const FLIP_START_COIN = 'GAME_START/FLIP_START_COIN';
export const END_FLIP_COIN = 'GAME_START/END_FLIP_COIN';

export const SET_FIRST_PLAYER = 'GAME_START/SET_FIRST_PLAYER';
export const END_SET_FIRST_PLAYER = 'GAME_START/END_SET_FIRST_PLAYER';

export const PUT_ACTIVE_POKEMON = 'GAME_START/PUT_ACTIVE_POKEMON';
export const END_PUT_ACTIVE = 'GAME_START/END_PUT_ACTIVE';

export const SET_READY = 'GAME_START/SET_READY';
export const END_SET_READY = 'GAME_START/END_SET_READY';
