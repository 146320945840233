import { fromJS } from 'immutable';
import { SET_DECKS } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  decks: [],
  decksLoaded: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_DECKS:
      return state.set('decks', action.payload.get('decks'))
        .set('decksLoaded', true);

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
