import { combineReducers } from 'redux';
import app from './App/reducer';
import activeGame from './ActiveGame/reducer';
import attackButton from './AttackButton/reducer';
import card from './Card/reducer';
import confirmButton from './ConfirmButton/reducer';
import deck from './Deck/reducer';
import decks from './Decks/reducer';
import gameStart from './GameStart/reducer';
import games from './Games/reducer';
import hand from './Hand/reducer';
import login from './Login/reducer';
import passButton from './PassButton/reducer';
import pokemon from './Pokemon/reducer';
import prizes from './Prizes/reducer';
import retreatButton from './RetreatButton/reducer';
import users from './Users/reducer';
import { LOGOUT } from './Login/constants';

const appReducer = combineReducers({
  // core reducers (not to reset with APP_RESET)
  app,
  login,

  // reducers to reset with APP_RESET
  activeGame,
  attackButton,
  card,
  confirmButton,
  deck,
  decks,
  gameStart,
  games,
  hand,
  passButton,
  pokemon,
  prizes,
  retreatButton,
  users,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined; // reset the app state
  }
  return appReducer(state, action);
};

export default rootReducer;
