import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import { login } from '../Login/actions';
import { selectAuthenticated, selectError, selectLoggingIn } from './selectors';

const Form = ({ loginAction, authenticated, loggingIn, error }) => {

  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');

  if (authenticated) {
    return <Redirect to="/" />;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    loginAction(email, password);
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="text" name="email" id="email"
            onChange={({ target: { value } }) => changeEmail(value)}
            value={email}
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password" name="password" id="password"
            onChange={({ target: { value } }) => changePassword(value)}
            value={password}
          />
        </div>
        <button disabled={loggingIn}>Sign In</button>
      </form>
      {error && <p>Error: {error}</p>}
    </Fragment>
  );
};

Form.propsTypes = {
  authenticated: PropTypes.bool.isRequired,
  loggingIn: PropTypes.bool.isRequired,
  loginAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    authenticated: selectAuthenticated,
    error: selectError,
    loggingIn: selectLoggingIn,
  }), {
    loginAction: login,
  }
)(Form);
