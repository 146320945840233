import styled from 'styled-components/macro';
import { connectedStyle } from '../App/styled';
import { cardSizeRatio, handleSize } from '../Card/styled';

export const GridViewOuter = connectedStyle(styled.div`
  position: absolute;
  bottom: 100%;
  width: 100%;
  height: ${props => props.windowHeight}px;
  overflow-y: scroll;
`);

export const GridViewStyles = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 33.33%;
  }
`;

const triangleSize = '10px';

const triangle = props => props.isFirstCard && `
  &::before {
    content: '';
    position: absolute;
    border-left: solid ${triangleSize} transparent;
    border-right: solid ${triangleSize} transparent;
    border-top: solid ${triangleSize} #333;
    top: calc(100% - ${triangleSize});
  }
`;

export const LargeSlider = connectedStyle(styled.div`
  visibility: ${props => props.viewingCard ? 'visible' : 'hidden'};
  pointer-events: ${props => props.viewingCard ? 'auto' : 'none'};
  position: absolute;
  bottom: 100%;
  width: ${props => handleSize(props.windowWidth, .5, true)}px;
  height: ${props => handleSize(props.windowWidth, .5, true) * cardSizeRatio}px;
`);

export const LargeSlide = styled.div`
  
`;

export const SmallSlider = connectedStyle(styled.div`
  width: ${props => props.windowWidth}px;
`);

export const SmallSlide = connectedStyle(styled.div`
  display: flex;
  justify-content: center;
  width: ${props => props.windowWidth / props.slidesToShow}px;
  margin-bottom: ${triangleSize};
  ${triangle}
`);

export const StyledPile = connectedStyle(styled.div`
  width: ${props => props.windowWidth}px;
`);
