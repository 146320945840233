import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import PassButton from '../PassButton';
import AttackButton from '../AttackButton';
import RetreatButton from '../RetreatButton';
import ContextMenu from '../ContextMenu';
import { ACTIVE } from '../ActiveGame/contextMenuTypes';
import { selectActivePokemon } from '../ActiveGame/selectors';
import Energies from '../Pokemon/Energies';

const ActivePokemonMenu = ({ activePokemon, userId }) => (
  <ContextMenu menu={ACTIVE} userId={userId}>
    <h3>Active Pokémon Menu</h3>
    Active Pokémon:
    {activePokemon ?
      <div>
        <p>{activePokemon.getIn(['card', 'name'], 'Set')}</p>
        <p>HP: {activePokemon.getIn(['card', 'hp'])}</p>
        <p>Damage: {activePokemon.get('damage')}</p>
      </div>
      : 'none'}
    {activePokemon && (
      <Fragment>
        <AttackButton userId={userId} />
        <PassButton userId={userId} />
        <RetreatButton userId={userId} />
        <br />
        <Energies energyAttachments={activePokemon.get('energyAttachments')} />
      </Fragment>
    )}
  </ContextMenu>
);

ActivePokemonMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map)
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
  })
)(ActivePokemonMenu);
