import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import ContextMenu from '../ContextMenu';
import ConfirmButton from '../ConfirmButton';
import { viewCard } from '../Card/actions';
import { BENCH } from '../ActiveGame/contextMenuTypes';
import { selectActivePokemon, selectBench } from '../ActiveGame/selectors';
import { putActivePokemon } from '../Pokemon/actions';
import { selectActiveBenchPosition, selectPuttingActive } from '../Pokemon/selectors';
import Energies from '../Pokemon/Energies';

const BenchContextMenu = (
  {
    activeBenchPosition,
    activePokemon,
    bench,
    putActivePokemonAction,
    puttingActive,
    userId,
    viewCardAction,
  }
) => {

  const activeBenchCard = bench.get('cards').find(card => card.get('order') === activeBenchPosition);

  if (!activeBenchCard) {
    return null;
  }

  return (
    <ContextMenu menu={BENCH} userId={userId}>
      <h3>Bench Menu</h3>
      <ul>
        <li>
          Bench Pile Count: {bench.get('count')}
        </li>
        <li>
          <button onClick={() => viewCardAction(activeBenchCard)}>View</button>
        </li>
        {!activePokemon && <li>
          <ConfirmButton
            onConfirm={() => putActivePokemonAction(activeBenchCard.get('order'))}
            disabled={puttingActive}
          >
            Set Active
          </ConfirmButton>
        </li>}
      </ul>
      <Energies energyAttachments={activeBenchCard.get('energyAttachments')} />
    </ContextMenu>
  );
};

BenchContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  activeBenchPosition: PropTypes.number,
  bench: PropTypes.instanceOf(Map),
  puttingActive: PropTypes.bool,
  putActivePokemonAction: PropTypes.func,
  viewCardAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
    activeBenchPosition: selectActiveBenchPosition,
    bench: selectBench,
    puttingActive: selectPuttingActive,
  }), {
    putActivePokemonAction: putActivePokemon,
    viewCardAction: viewCard,
  }
)(BenchContextMenu);
