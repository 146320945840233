import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { connectedStyle } from '../App/styled';

export const modalName = 'modal';
export const modalTimeout = 200;

export const cardSizeRatio = 624 / 455;

export const handleSize = (windowWidth, ratio, minSize = false) => {
  const size = windowWidth * ratio;
  return minSize ? Math.max(size, 375) : size;
};

export const Modal = connectedStyle(styled.div`
  position: absolute;
  top: 0;
  width: ${props => props.windowWidth * .1}px;
  height: ${props => props.windowWidth * .1 * cardSizeRatio}px;
  left: -${props => props.windowWidth * .1}px;
  transition: left ease ${modalTimeout}ms;

  &.${modalName}-enter {
     left: -${props => props.windowWidth * .1}px;
  }

  &.${modalName}-enter-active, &.${modalName}-enter-done, &.${modalName}-exit {
    left: 0;
  }

  &.${modalName}-exit-active {
    left: -${props => props.windowWidth * .1}px;
  }
`);

const Image = connectedStyle(styled.div`
  position: relative;
  background: left/contain no-repeat url(${props => props.image});
  width: ${props => handleSize(props.windowWidth, props.ratio, props.minSize)}px;
  height: ${props => handleSize(props.windowWidth, props.ratio, props.minSize) * cardSizeRatio}px;
  ${props => props.dim && 'filter: saturate(.2)'};
`);

Image.propTypes = {
  image: PropTypes.string.isRequired,
  dim: PropTypes.bool,
  ratio: PropTypes.number,
  minSize: PropTypes.bool,
};

Image.defaultProps = {
  ratio: .1,
};

export { Image };
