import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { selectAttackFlowing, selectAttacking, selectCanAttack } from './selectors';
import { attack, attackEnd, attackStart } from './actions';
import { selectActivePokemon } from '../ActiveGame/selectors';
import ConfirmButton from '../ConfirmButton';

const AttackButton = (
  {
    attackStartAction,
    attacking,
    attackAction,
    attackEndAction,
    activePokemon,
    attackFlowing,
    canAttack,
  }
) => {
  if (!canAttack) {
    return null;
  }

  const moves = activePokemon.getIn(['card', 'moves']);

  const moveOne = moves.find(move => move.get('number') === 1 && move.get('is_power') !== true);
  const moveTwo = moves.find(move => move.get('number') === 2 && move.get('is_power') !== true);

  return (
    <Fragment>
      <button onClick={attackStartAction} disabled={attacking}>Attack</button>
      {attackFlowing && <ol>
        {moveOne && <li>
          <ConfirmButton
            onConfirm={() => attackAction(moveOne.get('id'))}
            onCancel={attackEndAction}
            disabled={attacking}
          >
            {moveOne.get('name')}
          </ConfirmButton>
        </li>}
        {moveTwo && <li>
          <ConfirmButton
            onConfirm={() => attackAction(moveTwo.get('id'))}
            onCancel={attackEndAction}
            disabled={attacking}
          >
            {moveTwo.get('name')}
          </ConfirmButton>
        </li>}
      </ol>}
    </Fragment>
  );
};

AttackButton.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  attacking: PropTypes.bool,
  attackFlowing: PropTypes.bool,
  canAttack: PropTypes.bool,
  attackAction: PropTypes.func,
  attackStartAction: PropTypes.func,
  attackEndAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    attacking: selectAttacking,
    attackFlowing: selectAttackFlowing,
    activePokemon: selectActivePokemon,
    canAttack: selectCanAttack,
  }),
  {
    attackAction: attack,
    attackStartAction: attackStart,
    attackEndAction: attackEnd,
  }
)(AttackButton);
