import axios from 'axios';
import cookieManager from './cookieManager';
import { APP_URL } from '../env';

export function imageUrl(url) {
  return `${APP_URL}/image${url}`
}

export function cardImageUrl(card = null) {
  return imageUrl(
    card && card.get('image') ? card.get('image') : '/pokemon-card.jpg'
  );
}

export function setAxiosAccessToken() {
  axios.defaults.headers.common['Authorization'] = `Bearer ${cookieManager.getAccessToken()}`;
}

export function normalize(entity) {
  if (entity && typeof entity === 'object') {
    if (entity.data) {
      entity = normalize(entity.data);
    }

    for (const value in entity) {
      if (entity.hasOwnProperty(value)) {
        entity[value] = normalize(entity[value]);
      }
    }

    return entity;
  }

  if (Array.isArray(entity)) {
    return entity.map(normalize);
  }

  return entity;
}

export function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this, args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}
