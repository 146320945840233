import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { appSetup, appLoadedSuccess, appReset, setupResize } from './actions';
import { selectAppLoaded } from './selectors';
import ActiveGame from '../ActiveGame';
import Errors from '../Errors';
import Games from '../Games';
import Home from '../Home';
import Login from '../Login';
import MainMenu from '../MainMenu';
import { loginSuccess } from '../Login/actions';
import cookieManager from '../services/cookieManager';

function App({ appLoaded, appSetupAction, appLoadedSuccessAction, location, appResetAction, setupResizeAction }) {

  useEffect(() => {
    setupResizeAction();
  }, [setupResizeAction]);

  // reset app when route changes
  useEffect(() => {
    appResetAction();
  }, [location.pathname, appResetAction]);

  // initialize token, or mark app as loaded so login can happen
  useEffect(() => {
    const accessToken = cookieManager.getAccessToken();

    if (accessToken) {
      appSetupAction();
    } else {
      appLoadedSuccessAction();
    }
  }, [appLoaded, appSetupAction, appLoadedSuccessAction]);

  if (!appLoaded) {
    return 'Loading app...';
  }

  return (
    <div>
      <header>
        Pokémon TCG
      </header>

      <MainMenu />

      <Errors />

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/games/:id">
          <ActiveGame />
        </Route>
        <Route path="/games">
          <Games />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  );
}

App.propTypes = {
  appLoaded: PropTypes.bool,
  loginSuccessAction: PropTypes.func,
  appSetupAction: PropTypes.func,
  appLoadedSuccess: PropTypes.func,
  appResetAction: PropTypes.func,
  setupResizeAction: PropTypes.func,
  location: PropTypes.object,
};

export default compose(
  connect(
    (state) => ({
      appLoaded: selectAppLoaded(state),
    }), {
      appLoadedSuccessAction: appLoadedSuccess,
      appSetupAction: appSetup,
      loginSuccessAction: loginSuccess,
      appResetAction: appReset,
      setupResizeAction: setupResize,
    }
  ),
  withRouter
)(App);
