import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List, Map } from 'immutable';
import {
  selectCanRetreat,
  selectChoosingEnergies,
  selectEnergyAttachmentIds,
  selectRetreatFlowing,
  selectRetreating
} from './selectors';
import { chooseBenchPosition, retreat, retreatDone, retreatStart } from './actions';
import { selectActivePokemon } from '../ActiveGame/selectors';
import { setContextMenu } from '../ActiveGame/actions';
import { RETREAT } from '../ActiveGame/contextMenuTypes';

const RetreatButton = (
  {
    activePokemon,
    canRetreat,
    retreatStartAction,
    retreatDoneAction,
    retreatFlowing,
    energyAttachmentIds,
    retreating,
    choosingEnergies,
    chooseBenchPositionAction,
    setContextMenuAction,
    userId,
  }
) => {

  if (!canRetreat) {
    return null;
  }

  const count = activePokemon.getIn(['card', 'retreat_cost']);

  const energyAttachments = activePokemon.get('energyAttachments');

  const retreatAndOpenMenu = () => {
    retreatStartAction();
    setContextMenuAction(RETREAT, userId);
  };

  return (
    <Fragment>
      <button
        onClick={count === 0 ? chooseBenchPositionAction : retreatAndOpenMenu}
        disabled={retreatFlowing}
      >
        Retreat
      </button>
      {choosingEnergies && energyAttachments.size < count && <p>Not enough attached energies to retreat.</p>}
      {!retreating && <Fragment>
        {retreatFlowing && <button onClick={retreatDoneAction}>Cancel</button>}
        {!choosingEnergies && energyAttachmentIds.size !== 0 && <button onClick={retreatAndOpenMenu}>
          Edit Energies
        </button>}
      </Fragment>}
    </Fragment>
  );
};

RetreatButton.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  canRetreat: PropTypes.bool,
  retreating: PropTypes.bool,
  retreatStartAction: PropTypes.func,
  retreatAction: PropTypes.func,
  retreatDoneAction: PropTypes.func,
  energyAttachmentIds: PropTypes.instanceOf(List),
  chooseBenchPositionAction: PropTypes.func,
  setContextMenuAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    activePokemon: selectActivePokemon,
    canRetreat: selectCanRetreat,
    retreating: selectRetreating,
    retreatFlowing: selectRetreatFlowing,
    energyAttachmentIds: selectEnergyAttachmentIds,
    choosingEnergies: selectChoosingEnergies,
  }),
  {
    retreatStartAction: retreatStart,
    retreatAction: retreat,
    retreatDoneAction: retreatDone,
    chooseBenchPositionAction: chooseBenchPosition,
    setContextMenuAction: setContextMenu,
  }
)(RetreatButton);
