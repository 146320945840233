import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_DECKS } from './constants';
import { setDecks } from './actions';
import { all } from '../services/deck';

function* getDecksTask() {
  const decks = yield call(all);
  yield put(setDecks(decks))
}

function* getDecksWatcher() {
  yield takeLatest(GET_DECKS, getDecksTask);
}

export default [
  getDecksWatcher,
];
