import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ActivePokemon from '../ActivePokemon';
import Bench from '../Bench';
import Deck from '../Deck';
import DiscardPile from '../DiscardPile';
import Prizes from '../Prizes';
import { Root } from './styled';

const Player = ({ user, opponent }) => (
  <Root opponent={opponent}>
    <ActivePokemon userId={user.get('id')} />
    <Bench userId={user.get('id')} />
    <DiscardPile userId={user.get('id')} />
    <Deck userId={user.get('id')} />
    <Prizes userId={user.get('id')} />
  </Root>
);

Player.propTypes = {
  discardPile: PropTypes.instanceOf(Map),
  prizePile: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(Map),
  opponent: PropTypes.bool,
};

export default Player;
