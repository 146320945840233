import { fromJS } from 'immutable';
import { DRAW, END_DRAW, } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  drawing: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case DRAW:
      return state.set('drawing', true);

    case END_DRAW:
      return state.set('drawing', false);

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
