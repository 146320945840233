import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List, Map } from 'immutable';
import { setDeck } from './actions';
import { getDecks } from '../Decks/actions';
import { selectDecks, selectDecksLoaded } from '../Decks/selectors';
import { selectDeckPile, selectSettingDeck } from './selectors';

const SetDeck = ({ setDeckAction, deckPile, decks, decksLoaded, setting, getDecksAction }) => {
  const [deckId, changeDeckId] = useState('');

  useEffect(() => {
    if (decks.size) {
      changeDeckId(decks.first().get('id'));
    }
  }, [decks]);

  function handleSubmit(e) {
    e.preventDefault();
    setDeckAction(deckId);
  }

  useEffect(() => {
    getDecksAction();
  }, [getDecksAction]);

  if (deckPile) {
    return null;
  }

  if (!decksLoaded) {
    return 'Loading set deck form...';
  }

  if (setting) {
    return 'Setting deck...';
  }

  return (
    <form onSubmit={handleSubmit} style={{ display: 'inline-block' }}>
      <select name="" id="" onChange={(e) => changeDeckId(e.target.value)} value={deckId}>
        {decks.map((deck) => (
          <option key={deck.get('id')} value={deck.get('id')}>{deck.get('name')}</option>
        ))}
      </select>
      <button>Set Deck</button>
    </form>
  );
};

SetDeck.propTypes = {
  userId: PropTypes.number.isRequired,
  setDeckAction: PropTypes.func.isRequired,
  getDecksAction: PropTypes.func.isRequired,
  decks: PropTypes.instanceOf(List).isRequired,
  deckPile: PropTypes.instanceOf(Map),
  decksLoaded: PropTypes.bool,
  setting: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    deckPile: selectDeckPile,
    decks: selectDecks,
    decksLoaded: selectDecksLoaded,
    setting: selectSettingDeck,
  }), {
    setDeckAction: setDeck,
    getDecksAction: getDecks,
  }
)(SetDeck);
