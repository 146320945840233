import { MAIN } from './contextMenuTypes';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectActiveContextMenu, selectGameIsStarted } from './selectors';
import SetDeck from './SetDeck';
import GameStart from '../GameStart';
import ContextMenu from '../ContextMenu';
import ActionChooser from '../ActionChooser';

const MainContextMenu = ({ gameIsStarted, userId }) => {
  return (
    <ContextMenu menu={MAIN} userId={userId}>
      <h3>Main</h3>
      <SetDeck userId={userId} />
      {!gameIsStarted && <GameStart userId={userId} />}
      <ActionChooser userId={userId} />
    </ContextMenu>
  );
};

MainContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  menu: PropTypes.string,
  gameIsStarted: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    menu: selectActiveContextMenu,
    gameIsStarted: selectGameIsStarted,
  })
)(MainContextMenu);