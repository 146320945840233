import { fromJS } from 'immutable';
import {
  ATTACK_START,
  ATTACK,
  ATTACK_END,
} from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  attackFlowing: false,
  attacking: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case ATTACK_START:
      return state.set('attackFlowing', true);

    case ATTACK:
      return state.set('attacking', true);

    case ATTACK_END:
      return state.set('attackFlowing', false).set('attacking', false);

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
