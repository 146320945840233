import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { selectAuthenticated } from '../Login/selectors';
import { logout } from '../Login/actions';

const MainMenu = ({ authenticated, logoutAction }) => (
  <nav>
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      {!authenticated && <li>
        <Link to="/login">Login</Link>
      </li>}
      {authenticated && <li>
        <button onClick={logoutAction}>Logout</button>
      </li>}
      {authenticated && <li>
        <Link to="/games">Games</Link>
      </li>}
    </ul>
  </nav>
);

MainMenu.propTypes = {
  appLoaded: PropTypes.bool,
  loginSuccessAction: PropTypes.func,
  appLoadedAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    authenticated: selectAuthenticated,
  }), {
    logoutAction: logout,
  }
)(MainMenu);
