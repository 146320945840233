import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import ConfirmButton from '../ConfirmButton';
import { selectCanPass, selectPassing } from './selectors';
import { pass } from './actions';
import { selectActivePokemon } from '../ActiveGame/selectors';

const PassButton = ({ activePokemon, canPass, passAction, passing }) => {

  if (!canPass) {
    return null;
  }

  return (
    <ConfirmButton onConfirm={passAction} disabled={passing || !activePokemon}>Pass</ConfirmButton>
  );
};

PassButton.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePokemon: PropTypes.instanceOf(Map),
  canPass: PropTypes.bool.isRequired,
  passAction: PropTypes.func.isRequired,
  passing: PropTypes.bool.isRequired,
};

export default connect(
  createStructuredSelector({
    canPass: selectCanPass,
    passing: selectPassing,
    activePokemon: selectActivePokemon,
  }),
  {
    passAction: pass,
  }
)(PassButton);
