import { fromJS } from 'immutable';
import { GET_DECKS, SET_DECKS } from './constants';

export const getDecks = () => ({
  type: GET_DECKS,
});

export const setDecks = (decks) => ({
  type: SET_DECKS,
  payload: fromJS({ decks }),
});