import styled from 'styled-components/macro';
import { connectedStyle } from '../App/styled';

export const Root = connectedStyle(styled.ol`
  position: absolute;
  margin: 0;
  padding: 0;
  bottom: ${props => props.windowHeight * 0.132}px;
  list-style: none;

  & > li {
    position: absolute;
    bottom: 0;
    
    &:nth-child(1) {
      left: ${props => props.windowWidth * 0.266}px;
    }
    
    &:nth-child(2) {    
      left: ${props => props.windowWidth * 0.379}px;
    }
    
    &:nth-child(3) {
      left: ${props => props.windowWidth * 0.491}px;
    }
    
    &:nth-child(4) {
      left: ${props => props.windowWidth * 0.604}px;
    }
    
    &:nth-child(5) {
      left: ${props => props.windowWidth * 0.718}px;
    }
  }
`);
