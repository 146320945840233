import { call, select, put, takeLatest } from 'redux-saga/effects'
import { ATTACK } from './constants';
import activePokemon from '../services/activePokemon';
import { selectActiveGame } from '../ActiveGame/selectors';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { attackEnd } from './actions';

function* attackTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(activePokemon.attack, gameId, action.payload.get('moveId'));

  yield call(handleUpdateGame, game);

  yield put(attackEnd());
}

function* attackWatcher() {
  yield takeLatest(ATTACK, attackTask);
}

export default [
  attackWatcher,
];
