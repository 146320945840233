import { fromJS } from 'immutable';
import {
  ATTACK_START,
  ATTACK,
  ATTACK_END,
} from './constants';

export const attackStart = () => ({
  type: ATTACK_START,
});

export const attack = (moveId) => ({
  type: ATTACK,
  payload: fromJS({ moveId }),
});

export const attackEnd = () => ({
  type: ATTACK_END,
});