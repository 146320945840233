import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ContextMenu from '../ContextMenu';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { PlainButton, PlainList } from '../ActiveGame/styled';

const Cards = ({ pile, menu, menuType, userId, desc }) => {

  const [card, setActiveCard] = useState(null);

  const cards = pile.get('cards');

  useEffect(() => {
    // don't set null unless the card is gone
    setActiveCard(null);
  }, [cards]);

  if (!pile.get('cards').size) {
    return null;
  }

  let orderedCards = cards.sortBy(card => card.get('order'));

  if (desc) {
    orderedCards = orderedCards.reverse();
  }

  return (<div>
    <PlainList>
      {orderedCards.map((card, i) => (
        <li key={i}>
          <PlainButton onClick={() => setActiveCard(card)}>
            <Image image={cardImageUrl(card)} />
          </PlainButton>
        </li>
      ))}
    </PlainList>
    {menu && card && <ContextMenu menu={menuType} userId={userId}>
      {React.cloneElement(menu, { card })}
    </ContextMenu>}
  </div>);
};

Cards.propTypes = {
  pile: PropTypes.instanceOf(Map).isRequired,
  userId: PropTypes.number.isRequired,
  menu: PropTypes.node,
  menuType: PropTypes.string,
  desc: PropTypes.bool,
};

export default Cards;
