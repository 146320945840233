import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectErrors } from '../App/selectors';
import { List } from 'immutable';
import { appReset, clearErrors } from '../App/actions';

const isProduction = process.env.NODE_ENV === 'production';

const Errors = ({ errors, clearErrorsAction, appResetAction }) => {
  const onClear = useCallback(function () {
    clearErrorsAction();
    appResetAction();
  }, [clearErrorsAction, appResetAction]);

  return (
    <div>
      {errors.size !== 0 && <button onClick={onClear}>Clear errors</button>}
      {errors.map((error, i) => {
        let message = error.message;

        let userError = 'Whoops, there was an error.';

        if (error.response) {
          if (typeof error.response.data === 'object' && error.response.data.message) {
            message = error.response.data.message;
          } else if (typeof error.response.data === 'string') {
            message = error.response.data;
          }

          message = `${error.response.status}: ${message}`;
          userError = error.response.statusText;
        }

        if (isProduction) {
          console.error(message);
        }

        return <p key={i}>
          {userError}
          {!isProduction && <Fragment><br />{message}</Fragment>}
        </p>;
      })}
    </div>
  );
};

Errors.propTypes = {
  errors: PropTypes.instanceOf(List),
  clearErrorsAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    errors: selectErrors,
  }),
  {
    clearErrorsAction: clearErrors,
    appResetAction: appReset,
  }
)(Errors);
