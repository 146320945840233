import { fromJS } from 'immutable';
import { SET_CARD, UNSET_CARD } from './constants';

export const viewCard = (card) => ({
  type: SET_CARD,
  payload: fromJS({ card }),
});

export const unsetCard = () => ({
  type: UNSET_CARD,
});