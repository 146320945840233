import { createSelector } from 'reselect';
import { fromJS } from 'immutable';
import { selectCurrentUser } from '../Login/selectors';

const selectDomain = (state) => state.activeGame;

export const selectActiveContextUserId = createSelector(
  [selectDomain], (state) => state.get('activeContextUserId'),
);

export const selectActiveContextMenu = createSelector(
  [selectDomain], (state) => state.get('activeContextMenu'),
);

export const selectSettingDeck = createSelector(
  [selectDomain], (state) => state.get('settingDeck'),
);

export const selectVersion = createSelector(
  [selectDomain], (state) => state.get('version'),
);

export const selectActiveGame = createSelector(
  [selectDomain], (state) => state.get('activeGame'),
);

export const selectGameIsStarted = createSelector(
  [selectActiveGame], (game) => !!game.get('active_player_id'),
);

export const selectGameTurn = createSelector(
  [selectDomain], (state) => state.get('gameTurn'),
);

export const createSelectPlayerEntity = (entityKey, userIdColumn = 'user_id') => createSelector(
  [selectDomain, selectCurrentUser], (state, currentUser) => state.get(entityKey).find(
    entity => entity.get(userIdColumn) === currentUser.get('id')
  ),
);

export const createSelectOpponentEntity = (entityKey, userIdColumn = 'user_id') => createSelector(
  [selectDomain, selectCurrentUser], (state, currentUser) => state.get(entityKey).find(
    entity => entity.get(userIdColumn) !== currentUser.get('id')
  ),
);

const mapPlayerDataToProps = (
  activePokemon,
  bench,
  discardPile,
  deckPile,
  gamePlayer,
  hand,
  prizePile,
  user,
) => fromJS({
  activePokemon,
  bench,
  discardPile,
  deckPile,
  gamePlayer,
  hand,
  prizePile,
  user,
});

export const selectPlayer = createSelector([
  createSelectPlayerEntity('activePokemon'),
  createSelectPlayerEntity('benches'),
  createSelectPlayerEntity('discardPiles'),
  createSelectPlayerEntity('deckPiles'),
  createSelectPlayerEntity('gamePlayers'),
  createSelectPlayerEntity('hands'),
  createSelectPlayerEntity('prizePiles'),
  createSelectPlayerEntity('users', 'id'),
], mapPlayerDataToProps);

export const selectOpponent = createSelector([
  createSelectOpponentEntity('activePokemon'),
  createSelectOpponentEntity('benches'),
  createSelectOpponentEntity('discardPiles'),
  createSelectOpponentEntity('deckPiles'),
  createSelectOpponentEntity('gamePlayers'),
  createSelectOpponentEntity('hands'),
  createSelectOpponentEntity('prizePiles'),
  createSelectOpponentEntity('users', 'id'),
], mapPlayerDataToProps);

export const selectIsActivePlayer = createSelector(
  [selectActiveGame, selectPlayer, (state, { userId }) => userId],
  (game, player, userId) => userId === player.getIn(['user', 'id']) && userId === game.get('active_player_id')
);

export const selectActivePokemon = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('activePokemon').find(
    entity => entity.get('user_id') === userId
  )
);

export const selectBench = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('benches').find(
    entity => entity.get('user_id') === userId
  )
);

export const selectDeckPile = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('deckPiles').find(
    entity => entity.get('user_id') === userId
  )
);

export const selectDiscardPile = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('discardPiles').find(
    entity => entity.get('user_id') === userId
  )
);

export const selectGamePlayer = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('gamePlayers').find(
    entity => entity.get('user_id') === userId
  )
);

export const selectHand = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('hands').find(
    entity => entity.get('user_id') === userId
  )
);

export const selectPrizePile = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('prizePiles').find(
    entity => entity.get('user_id') === userId
  )
);


export const selectUser = createSelector(
  [selectDomain, (state, { userId }) => userId],
  (state, userId) => state.get('users').find(
    entity => entity.get('id') === userId
  )
);
