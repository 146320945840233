import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ConfirmButton from '../ConfirmButton';
import { PlainButton } from '../ActiveGame/styled';

const ActionableButton = ({ onClick, disabled, children, onCancel, plain }) => {

  const onClickCallback = useCallback(() => onClick && onClick(), [onClick]);

  if (!onClick) {
    return children;
  }

  if (onCancel) {
    return (
      <ConfirmButton onConfirm={onClickCallback} onCancel={onCancel} disabled={disabled} plain={plain}>
        {children}
      </ConfirmButton>
    );
  }

  const Button = plain ? PlainButton : 'button';

  return (
    <Button onClick={onClickCallback} disabled={disabled}>{children}</Button>
  );
};

ActionableButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  plain: PropTypes.bool,
  onCancel: PropTypes.func,
};

ActionableButton.defaultProps = {
  disabled: false,
};

export default ActionableButton;
