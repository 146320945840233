import { createSelector } from 'reselect';

const selectDomain = (state) => state.app;

export const selectAppLoaded = createSelector(
  [selectDomain], (state) => state.get('appLoaded')
);

export const selectErrors = createSelector(
  [selectDomain], (state) => state.get('errors')
);

export const selectWidth = createSelector(
  [selectDomain], (state) => state.get('width')
);

export const selectHeight = createSelector(
  [selectDomain], (state) => state.get('height')
);
