import styled from 'styled-components/macro';
import { imageUrl } from '../services/helpers';
import { connectedStyle } from '../App/styled';

export const PlainButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
`;

export const PlainList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const GameContainer = connectedStyle(styled.div`
  position: relative;
  width: ${props => props.windowWidth}px;
  height: ${props => props.windowHeight}px;
`);

export const GameMap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${imageUrl('/gamemap.jpg')});
  background-size: contain;
  background-repeat: no-repeat;
  ${props => !props.isFirstPlayer && 'transform: rotate(180deg)'};
`;
