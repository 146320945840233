import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ATTACH, BENCH, PUT_ACTIVE_POKEMON } from './constants';
import { endAttach, endBench, startAttach, startBench, endPutBenchAsActive, setActiveBenchPosition } from './actions';
import { selectAttachCardPosition, selectBenchPosition } from './selectors';
import gameTurnService from '../services/gameTurn';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { selectActiveGame } from '../ActiveGame/selectors';
import { selectHandPosition } from '../Hand/selectors';
import { setContextMenu } from '../ActiveGame/actions';
import { selectCurrentUser } from '../Login/selectors';
import { ACTIVE, BENCH as BENCH_MENU } from '../ActiveGame/contextMenuTypes';

function* attachTask(action) {
  yield put(startAttach());

  const energyPosition = yield select(selectAttachCardPosition);
  const benchPosition = action.payload.get('benchPosition');

  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameTurnService.attach, gameId, energyPosition, benchPosition);

  yield call(handleUpdateGame, game);

  yield put(endAttach());

  const user = yield select(selectCurrentUser);

  if (benchPosition) {
    yield put(setActiveBenchPosition(benchPosition));
    yield put(setContextMenu(BENCH_MENU, user.get('id')));
  } else {
    yield put(setContextMenu(ACTIVE, user.get('id')));
  }
}

function* attachWatcher() {
  yield takeLatest(ATTACH, attachTask);
}

function* benchTask() {
  yield put(startBench());

  const handPosition = yield select(selectHandPosition);
  const benchPosition = yield select(selectBenchPosition);

  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameTurnService.bench, gameId, handPosition, benchPosition);

  yield call(handleUpdateGame, game);

  yield put(endBench());
}

function* benchWatcher() {
  yield takeLatest(BENCH, benchTask);
}

function* putActivePokemonTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameTurnService.putBenchAsActivePokemon, gameId, action.payload.get('benchCardPosition'));

  yield call(handleUpdateGame, game);

  yield put(endPutBenchAsActive());
}

function* putActivePokemonWatcher() {
  yield takeLatest(PUT_ACTIVE_POKEMON, putActivePokemonTask);
}

export default [
  attachWatcher,
  benchWatcher,
  putActivePokemonWatcher,
];
