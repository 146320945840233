import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas'
import reducers from './reducers';

const options = {
  onError: function (e, { sagaStack }) {
    console.error(sagaStack);
  },
};

const sagaMiddleware = createSagaMiddleware(options);

const middleware = [
  applyMiddleware(sagaMiddleware),
  (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose,
];

function configureStore() {
  return createStore(
    reducers,
    compose(...middleware)
  );
}

const store = configureStore();

sagaMiddleware.run(rootSaga);


export default store;
