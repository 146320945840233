import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import ConfirmButton from '../ConfirmButton';
import ContextMenu from '../ContextMenu';
import { PRIZE } from '../ActiveGame/contextMenuTypes';
import { selectGamePlayer, selectPrizePile } from '../ActiveGame/selectors';
import { drawPrize } from './actions';
import { selectActivePrize, selectDrawing } from './selectors';

const PrizesContextMenu = ({ gamePlayer, drawPrizeAction, activePrize, drawing, prizePile, userId }) => (
  activePrize !== null && <ContextMenu menu={PRIZE} userId={userId}>
    <h3>Prize Menu</h3>
    <div>
      <div>Prize Pile Count: {prizePile.get('cards').size}</div>
      {gamePlayer.get('prizes') > 0 && <ConfirmButton onConfirm={() => drawPrizeAction(activePrize)} disabled={drawing}>
        Draw Prize
      </ConfirmButton>}
    </div>
  </ContextMenu>
);

PrizesContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  activePrize: PropTypes.number,
  gamePlayer: PropTypes.instanceOf(Map),
  drawing: PropTypes.bool,
  drawPrizeAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    activePrize: selectActivePrize,
    drawing: selectDrawing,
    gamePlayer: selectGamePlayer,
    prizePile: selectPrizePile,
  }), {
    drawPrizeAction: drawPrize,
  }
)(PrizesContextMenu);
