import { fromJS } from 'immutable';
import {
  END_FLIP_COIN,
  END_PUT_ACTIVE,
  END_SET_FIRST_PLAYER,
  END_SET_READY,
  FLIP_START_COIN,
  PUT_ACTIVE_POKEMON,
  SET_FIRST_PLAYER,
  SET_READY,
} from './constants';

export const flipStartCoin = (heads) => ({
  type: FLIP_START_COIN,
  payload: fromJS({ heads }),
});

export const endFlipCoin = () => ({
  type: END_FLIP_COIN,
});

export const setFirstPlayer = (firstPlayerId) => ({
  type: SET_FIRST_PLAYER,
  payload: fromJS({ firstPlayerId }),
});

export const endSetFirstPlayer = () => ({
  type: END_SET_FIRST_PLAYER,
});

export const putActivePokemon = (handPosition) => ({
  type: PUT_ACTIVE_POKEMON,
  payload: fromJS({ handPosition }),
});

export const endPutActive = () => ({
  type: END_PUT_ACTIVE,
});

export const setReady = () => ({
  type: SET_READY,
});

export const endSetReady = () => ({
  type: END_SET_READY,
});