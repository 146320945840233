import { fromJS } from 'immutable';
import { SET_GAMES, DELETE_GAME, CREATE_GAME_SUCCESS, CREATE_GAME } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  games: [],
  creating: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GAMES:
      return state.set('games', action.payload.get('games'));

    case CREATE_GAME:
      return state.set('creating', true);

    case CREATE_GAME_SUCCESS:
      return state.set('creating', false);

    case DELETE_GAME:
      return state.set('games', state.get('games').filterNot(
        (game) => game.get('id') === action.payload.get('gameId')
      ));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
