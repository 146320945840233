import axios from 'axios';
import { APP_URL, API_URL, CLIENT_ID, CLIENT_SECRET } from '../env';

const getAccessToken = async (email, password) => {
  const { data: token } = await axios.post(`${APP_URL}/oauth/token`, {
    grant_type: 'password',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    username: email,
    password,
  });

  return token;
};

const findCurrentUser = async () => {
  const response = await axios.get(`${API_URL}/currentUser`);

  return response.data.data;
};

export {
  getAccessToken,
  findCurrentUser,
}
