import { RETREAT, RETREAT_DONE, RETREAT_START, CHOOSE_BENCH_POSITION } from './constants';
import { isImmutable, fromJS, List } from 'immutable';

export const retreatStart = () => ({
  type: RETREAT_START,
});

export const chooseBenchPosition = (energyAttachmentIds) => ({
  type: CHOOSE_BENCH_POSITION,
  payload: fromJS({ energyAttachmentIds: isImmutable(energyAttachmentIds) ? energyAttachmentIds : List() }),
});

export const retreat = (benchPosition) => ({
  type: RETREAT,
  payload: fromJS({ benchPosition }),
});

export const retreatDone = () => ({
  type: RETREAT_DONE,
});

