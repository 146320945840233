import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { Root } from './styled';
import { selectPrizePile } from '../ActiveGame/selectors';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { setActivePrize } from './actions';
import { setContextMenu } from '../ActiveGame/actions';
import { PRIZE } from '../ActiveGame/contextMenuTypes';
import ActionableButton from '../ActionableButton';

const Prizes = ({ prizePile, setActivePrizeAction, setContextMenuAction, userId }) => {

  const onChange = useCallback((i = null) => {
    setActivePrizeAction(i);
    setContextMenuAction(PRIZE, userId);
  }, [userId, setActivePrizeAction, setContextMenuAction]);

  useEffect(function () {
    setActivePrizeAction(null);
  }, [prizePile, setActivePrizeAction]);

  const prizes = [];

  for (let i = 0; i < 6; i++) {
    const prize = prizePile.get('cards').find(card => card.get('order') === i);
    prizes.push(<div key={i}>
      {prize && <ActionableButton onClick={() => onChange(i)} plain>
        <Image image={cardImageUrl(prize.get('card'))} />
      </ActionableButton>}
    </div>)
  }

  return <Root>{prizes}</Root>;
};

Prizes.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  prizePile: PropTypes.instanceOf(Map),
  drawPrizeAction: PropTypes.func,
  drawing: PropTypes.bool,
};

export default connect(
  createStructuredSelector({
    prizePile: selectPrizePile,
  }), {
    setActivePrizeAction: setActivePrize,
    setContextMenuAction: setContextMenu,
  }
)(Prizes);
