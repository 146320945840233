import React, { Fragment } from 'react';
import Form from './Form';

const Login = () => (
  <Fragment>
    <h2>Login</h2>
    <Form />
  </Fragment>
);

export default Login;
