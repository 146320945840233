import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { GridViewOuter, GridViewStyles } from './styled';

const GridView = ({ cards, getSelectButton, getDim, onChange }) => {
  return (
    <GridViewOuter>
      <GridViewStyles>
        {cards.map((card, i) => (
          <div key={i} onClick={() => onChange(i)}>
            <Image
              ratio={.3333}
              image={cardImageUrl(card)}
              dim={getDim && getDim(card)}
            />
            {getSelectButton && getSelectButton(card)}
          </div>
        ))}
      </GridViewStyles>
    </GridViewOuter>
  );
};

GridView.propTypes = {
  cards: PropTypes.instanceOf(List).isRequired,
  getSelectButton: PropTypes.func,
  getDim: PropTypes.func,
};

export default GridView;