import { fromJS } from 'immutable';
import { GET_USERS, SET_USERS } from './constants';

export const getUsers = () => ({
  type: GET_USERS,
});

export const setUsers = (users) => ({
  type: SET_USERS,
  payload: fromJS({ users }),
});