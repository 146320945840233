import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

const Energies = ({ energyAttachments }) => (
  <Fragment>
    Energies ({energyAttachments.size})
    <ul>
      {energyAttachments.map(energy => (
        <li key={energy.get('id')}>{energy.getIn(['card', 'name'])}</li>
      ))}
    </ul>
  </Fragment>
);

Energies.propTypes = {
  energyAttachments: PropTypes.instanceOf(List),
};

export default Energies;
