import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Pile from '../Pile';

const SelectPile = ({ selectCount, onSelectDone, selectedIds: initialSelectedIds, ...rest }) => {
  const [selectedIds, updatedSelectedIds] = useState(initialSelectedIds);

  const selectedDisabled = selectCount === selectedIds.size;

  const getSelectButton = useCallback((cardable) => !selectedIds.includes(cardable.get('pivot_id')) ?
    (
      <button
        onClick={() => updatedSelectedIds(selectedIds.push(cardable.get('pivot_id')))}
        disabled={selectedDisabled}
      >
        Select
      </button>
    ) :
    (
      <button
        onClick={() => updatedSelectedIds(selectedIds.filterNot(selectedId => selectedId === cardable.get('pivot_id')))}
      >
        Unselect
      </button>
    ), [selectedIds, selectedDisabled]);

  const getDim = useCallback((cardable) => {
    return selectedDisabled && !selectedIds.includes(cardable.get('pivot_id'));
  }, [selectedDisabled, selectedIds]);

  const selectDoneButton = selectCount && onSelectDone && (<button
    disabled={selectCount !== selectedIds.size}
    onClick={() => onSelectDone(selectedIds)}
  >
    Done
  </button>);

  return (
    <Pile
      {...rest}
      getDim={getDim}
      getSelectButton={getSelectButton}
      selectDoneButton={selectDoneButton}
      selectionFeedbackMessage={`${selectedIds.size} / ${selectCount}`}
    />
  );
};


SelectPile.propTypes = {
  cardsOrCardables: PropTypes.instanceOf(List).isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  grid: PropTypes.bool,
  slider: PropTypes.bool,

  message: PropTypes.string.isRequired,
  selectedIds: PropTypes.instanceOf(List).isRequired,
  selectCount: PropTypes.number.isRequired,
  onSelectDone: PropTypes.func.isRequired,
};

export default SelectPile;
