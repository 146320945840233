import { put, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { setSize } from '../App/actions';
import { debounce } from './helpers';

export function registerResizing() {
  return function* () {
    const channel = eventChannel(emitter => {

      function handleResize({ target: { outerWidth, outerHeight } }) {
        emitter({ width: outerWidth, height: outerHeight });
      }

      setupResizer(debounce(handleResize, 300));

      return () => closeResizer(handleResize);
    });

    while (true) {
      let { width, height } = yield take(channel);
      yield put(setSize(width, height));
    }
  };
}

function setupResizer(callback) {
  window.addEventListener('resize', callback);
}

function closeResizer(callback) {
  window.removeEventListener('resize', callback);
}