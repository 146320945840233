import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { selectActiveGame } from '../ActiveGame/selectors';
import { flipStartCoin } from './actions';
import { selectFlippingCoin } from './selectors';

const SelectHeads = ({ flipStartCoinAction, game, flippingCoin }) => {
  if (game.get('start_chooser_id')) {
    return null;
  }
  return (
    <Fragment>
      Call Flip:
      <button onClick={() => flipStartCoinAction(true)} disabled={flippingCoin}>Heads</button>
      <button onClick={() => flipStartCoinAction(false)} disabled={flippingCoin}>Tails</button>
    </Fragment>
  )
};

SelectHeads.propTypes = {
  game: PropTypes.instanceOf(Map),
  flipStartCoinAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    game: selectActiveGame,
    flippingCoin: selectFlippingCoin,
  }),
  {
    flipStartCoinAction: flipStartCoin,
  }
)(SelectHeads);
