import { APP_LOADED_SUCCESS, APP_RESET, APP_SETUP, CLEAR_ERRORS, ERROR, SET_SIZE, SETUP_RESIZE } from './constants';
import { fromJS } from 'immutable';

export const error = (error) => ({
  type: ERROR,
  payload: fromJS({ error }),
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const appSetup = () => ({
  type: APP_SETUP,
});

export const appLoadedSuccess = () => ({
  type: APP_LOADED_SUCCESS,
});

export const setupResize = () => ({
  type: SETUP_RESIZE,
});

const gameMapRatio = 520 / 500;

export const setSize = (width, height) => {
  if (height < width) {
    width = height / gameMapRatio;
  } else {
    height = width * gameMapRatio;
  }
  return ({
    type: SET_SIZE,
    payload: fromJS({ width, height }),
  });
};

/** Reset everything except for login */
export const appReset = () => ({
  type: APP_RESET,
});
