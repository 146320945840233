import { fromJS } from 'immutable';
import { DRAW_PRIZE, END_DRAW_PRIZE, SET_ACTIVE_PRIZE } from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  drawing: false,
  activePrize: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case DRAW_PRIZE:
      return state.set('drawing', true);

    case END_DRAW_PRIZE:
      return state.set('drawing', false);

    case SET_ACTIVE_PRIZE:
      return state.set('activePrize', action.payload.get('position'));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
