import { createSelector } from 'reselect';
import { selectCurrentUser } from '../Login/selectors';

const selectDomain = (state) => state.users;

export const selectUsers = createSelector(
  [selectDomain, selectCurrentUser], (state, currentUser) => state.get('users')
    .filterNot(user => user.get('id') === currentUser.get('id'))
    .sortBy(user => user.get('name')),
);

export const selectUsersLoaded = createSelector(
  [selectDomain], (state) => state.get('usersLoaded')
);
