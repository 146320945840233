import styled from 'styled-components';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectWidth } from '../App/selectors';

const connectContextMenu = connect(
  createStructuredSelector({
    windowWidth: selectWidth,
  })
);

const Root = connectContextMenu(styled.div`
  display: ${props => {
  if (props.menu === props.activeContextMenu && props.userId === props.activeContextUserId) {
    return 'block';
  }
  return 'none';
}};
  position: relative;
  top: 100%;
  width: ${props => props.windowWidth}px;
`);

export {
  Root,
};
