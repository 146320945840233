import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ContextMenu from '../ContextMenu';
import { DISCARD } from '../ActiveGame/contextMenuTypes';
import Cards from '../Player/Cards';
import { selectDiscardPile } from '../ActiveGame/selectors';

const DiscardPileContextMenu = ({ discardPile, userId }) => (
  <ContextMenu menu={DISCARD} userId={userId}>
    Discard Pile Count: {discardPile.get('count')}
    <Cards pile={discardPile} userId={userId} desc />
  </ContextMenu>
);

DiscardPileContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  discardPile: PropTypes.instanceOf(Map),
};

export default connect(
  createStructuredSelector({
    discardPile: selectDiscardPile,
  })
)(DiscardPileContextMenu);
