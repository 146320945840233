import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { CSSTransition } from 'react-transition-group';
import { unsetCard } from './actions';
import { selectCard } from './selectors';
import { cardImageUrl } from '../services/helpers';
import { Image, Modal, modalName, modalTimeout } from './styled';

const Card = ({ card, unsetCardAction }) => {

  const [opened, open] = useState(false);

  const modal = useRef(null);

  const close = useCallback(() => card && open(false), [card]);
  const clickClose = useCallback(({ target }) => !modal.current.contains(target) && close(), [close]);
  const keyClose = useCallback(({ keyCode }) => keyCode === 27 && close(), [close]);

  useEffect(() => {
    if (card) {
      document.addEventListener('click', clickClose);
      document.addEventListener('keyup', keyClose);
    }

    return () => {
      document.removeEventListener('keyup', keyClose);
      document.removeEventListener('click', clickClose);
    }
  }, [card, clickClose, keyClose]);

  useEffect(() => {
    if (card) {
      open(true);
    }
  }, [card, open]);

  return (
    <CSSTransition in={opened} timeout={modalTimeout} classNames={modalName} onExited={unsetCardAction}>
      <Modal ref={modal}>
        <Image image={cardImageUrl(card)} />
      </Modal>
    </CSSTransition>
  );
};

Card.propTypes = {
  card: PropTypes.instanceOf(Map),
  unsetCardAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    card: selectCard,
  }),
  {
    unsetCardAction: unsetCard,
  }
)(Card);