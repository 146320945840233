import { call, put, select, takeLatest } from 'redux-saga/effects'
import { DRAW_PRIZE } from './constants';
import { endDrawPrize } from './actions';
import gameTurnService from '../services/gameTurn';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { selectActiveGame, selectPlayer } from '../ActiveGame/selectors';
import { setContextMenu } from '../ActiveGame/actions';
import { HAND } from '../ActiveGame/contextMenuTypes';

function* drawPrizeTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameTurnService.drawPrize, gameId, action.payload.get('prizePosition'));

  yield call(handleUpdateGame, game);

  yield put(endDrawPrize());

  const playerId = (yield select(selectPlayer)).getIn(['user', 'id']);
  yield put(setContextMenu(HAND, playerId));
}

function* drivePrizeWatcher() {
  yield takeLatest(DRAW_PRIZE, drawPrizeTask);
}

export default [
  drivePrizeWatcher,
];
