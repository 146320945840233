import { fromJS } from 'immutable';
import { DRAW_PRIZE, END_DRAW_PRIZE, SET_ACTIVE_PRIZE } from './constants';

export const drawPrize = (prizePosition) => ({
  type: DRAW_PRIZE,
  payload: fromJS({ prizePosition }),
});

export const endDrawPrize = () => ({
  type: END_DRAW_PRIZE,
});

export const setActivePrize = (position) => ({
  type: SET_ACTIVE_PRIZE,
  payload: fromJS({ position }),
});
