import { createSelector } from 'reselect';

const selectDomain = (state) => state.gameStart;

export const selectSettingReady = createSelector(
  [selectDomain], (state) => state.get('settingReady'),
);

export const selectPuttingActive = createSelector(
  [selectDomain], (state) => state.get('puttingActive'),
);

export const selectFlippingCoin = createSelector(
  [selectDomain], (state) => state.get('flippingCoin'),
);

export const selectSettingFirstPlayer = createSelector(
  [selectDomain], (state) => state.get('settingFirstPlayer'),
);
