import { createSelector } from 'reselect';
import { selectGameTurn, selectIsActivePlayer } from '../ActiveGame/selectors';

const selectDomain = (state) => state.retreatButton;

export const selectCanRetreat = createSelector(
  [selectIsActivePlayer, selectGameTurn],
  (isActivePlayer, gameTurn) => isActivePlayer && gameTurn.get('drew') && !gameTurn.get('attack_started')
);

export const selectRetreating = createSelector(
  [selectDomain], (state) => state.get('retreating'),
);

export const selectRetreatFlowing = createSelector(
  [selectDomain], (state) => state.get('retreatFlowing'),
);

export const selectChoosingEnergies = createSelector(
  [selectDomain], (state) => state.get('choosingEnergies'),
);

export const selectEnergyAttachmentIds = createSelector(
  [selectDomain], (state) => state.get('energyAttachmentIds'),
);

export const selectChoosingBenchPosition = createSelector(
  [selectDomain], (state) => state.get('choosingBenchPosition'),
);

export const selectBenchPosition = createSelector(
  [selectDomain], (state) => state.get('benchPosition'),
);