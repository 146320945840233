import { fromJS } from 'immutable';
import {
  ATTACH,
  ATTACH_CANCEL,
  BENCH,
  BENCH_CANCEL,
  END_ATTACH,
  END_BENCH,
  PUT_ACTIVE_POKEMON,
  END_PUT_BENCH_AS_ACTIVE_POKEMON,
  START_ATTACH,
  START_ATTACH_FLOW,
  START_BENCH,
  START_BENCH_FLOW, SET_ACTIVE_BENCH,
} from './constants';

export const startBenchFlow = (handPosition) => ({
  type: START_BENCH_FLOW,
  payload: fromJS({ handPosition }),
});

export const startBench = () => ({
  type: START_BENCH,
});

export const addToBench = (benchPosition) => ({
  type: BENCH,
  payload: fromJS({ benchPosition }),
});

export const addToBenchCancel = () => ({
  type: BENCH_CANCEL,
});

export const endBench = () => ({
  type: END_BENCH,
});

export const startAttachFlow = (attachCardPosition) => ({
  type: START_ATTACH_FLOW,
  payload: fromJS({ attachCardPosition }),
});

export const startAttach = () => ({
  type: START_ATTACH,
});

export const attach = (benchPosition = null) => ({
  type: ATTACH,
  payload: fromJS({ benchPosition }),
});

export const attachCancel = () => ({
  type: ATTACH_CANCEL,
});

export const endAttach = () => ({
  type: END_ATTACH,
});

export const putActivePokemon = (benchCardPosition) => ({
  type: PUT_ACTIVE_POKEMON,
  payload: fromJS({ benchCardPosition }),
});


export const endPutBenchAsActive = () => ({
  type: END_PUT_BENCH_AS_ACTIVE_POKEMON,
});

export const setActiveBenchPosition = (benchPosition) => ({
  type: SET_ACTIVE_BENCH,
  payload: fromJS({ benchPosition }),
});
