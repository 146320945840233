import { call, put, select, takeLatest } from 'redux-saga/effects'
import { RETREAT } from './constants';
import { retreatDone } from './actions';
import { retreat } from '../services/game';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { selectActiveGame } from '../ActiveGame/selectors';
import { selectEnergyAttachmentIds, selectBenchPosition } from './selectors';

function* retreatTask() {
  const gameId = (yield select(selectActiveGame)).get('id');

  const energyAttachmentIds = yield select(selectEnergyAttachmentIds);

  const benchPosition = yield select(selectBenchPosition);

  const game = yield call(retreat, gameId, benchPosition, energyAttachmentIds);

  yield call(handleUpdateGame, game);

  yield put(retreatDone());
}

function* retreatWatcher() {
  yield takeLatest(RETREAT, retreatTask);
}

export default [
  retreatWatcher,
];
