import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectConfirmed } from './selectors';
import { confirm } from './actions';
import { PlainButton } from '../ActiveGame/styled';

const ConfirmButton = ({ onConfirm, onCancel, disabled, children, confirmAction, confirmed, plain }) => {

  let identifier = useRef(Symbol('CONFIRM_BUTTON_IDENTIFIER')).current;

  const handleCancel = () => {
    confirmAction(null);
    onCancel && onCancel();
  };

  let confirmButtons;

  if (confirmed === identifier) {
    confirmButtons = (
      <Fragment>
        <button onClick={onConfirm} disabled={disabled}>Confirm</button>
        <button onClick={handleCancel} disabled={disabled}>Cancel</button>
      </Fragment>
    );
  }

  const Button = plain ? PlainButton : 'button';

  return (
    <Fragment>
      <Button
        onClick={() => confirmAction(identifier)}
        disabled={disabled}
        style={!disabled && onCancel ? { outline: 'dashed blue 2px' } : {}}
      >
        {children}
      </Button>
      {confirmButtons}
    </Fragment>
  );
};

ConfirmButton.propTypes = {
  onConfirm: PropTypes.func.isRequired,// passed in
  children: PropTypes.node.isRequired,// passed in
  disabled: PropTypes.bool,// passed in
  plain: PropTypes.bool,// passed in
  onCancel: PropTypes.func, // passed in
  confirmAction: PropTypes.func.isRequired,
  confirmed: PropTypes.symbol,
};

ConfirmButton.defaultProps = {
  disabled: false,
};

export default connect(
  createStructuredSelector({
    confirmed: selectConfirmed,
  }),
  {
    confirmAction: confirm,
  }
)(ConfirmButton);
