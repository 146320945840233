import { call, select, put, takeLatest } from 'redux-saga/effects'
import { FLIP_START_COIN, SET_FIRST_PLAYER, SET_READY } from './constants';
import gameStart from '../services/gameStart';
import { selectActiveGame } from '../ActiveGame/selectors';
import { handleUpdateGame } from '../ActiveGame/sagas';
import { endFlipCoin, endPutActive, endSetFirstPlayer, endSetReady } from './actions';
import { PUT_ACTIVE_POKEMON } from './constants';

function* flipStartCoinTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameStart.flipStartCoin, gameId, action.payload.get('heads'));

  yield call(handleUpdateGame, game);

  yield put(endFlipCoin())
}

function* flipStartCoinWatcher() {
  yield takeLatest(FLIP_START_COIN, flipStartCoinTask);
}

function* setFirstPlayerTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameStart.setFirstPlayer, gameId, action.payload.get('firstPlayerId'));

  yield call(handleUpdateGame, game);

  yield put(endSetFirstPlayer())
}

function* setFirstPlayerWatcher() {
  yield takeLatest(SET_FIRST_PLAYER, setFirstPlayerTask);
}


function* putActivePokemonTask(action) {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameStart.putActivePokemon, gameId, action.payload.get('handPosition'));

  yield call(handleUpdateGame, game);

  yield put(endPutActive());
}

function* putActivePokemonWatcher() {
  yield takeLatest(PUT_ACTIVE_POKEMON, putActivePokemonTask);
}

function* setReadyTask() {
  const gameId = (yield select(selectActiveGame)).get('id');
  const game = yield call(gameStart.setReady, gameId);

  yield call(handleUpdateGame, game);

  yield put(endSetReady());
}

function* setReadyWatcher() {
  yield takeLatest(SET_READY, setReadyTask);
}

export default [
  flipStartCoinWatcher,
  setFirstPlayerWatcher,
  putActivePokemonWatcher,
  setReadyWatcher,
];
