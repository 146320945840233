import { fromJS } from 'immutable';
import {
  ATTACH_CANCEL,
  BENCH,
  BENCH_CANCEL,
  END_ATTACH,
  END_BENCH,
  END_PUT_BENCH_AS_ACTIVE_POKEMON,
  PUT_ACTIVE_POKEMON,
  SET_ACTIVE_BENCH,
  START_ATTACH,
  START_ATTACH_FLOW,
  START_BENCH,
  START_BENCH_FLOW,
  UNSET_ACTIVE_BENCH,
} from './constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  attaching: false,
  attachCardPosition: null,
  attachFlowing: false,
  benchFlowing: false,
  activeBenchPosition: null,
  benchPosition: false,
  benching: false,
  puttingActive: false,
});

function reducer(state = initialState, action) {
  switch (action.type) {
    case START_ATTACH_FLOW:
      return state.set('attachFlowing', true)
        .set('attachCardPosition', action.payload.get('attachCardPosition'));

    case START_ATTACH:
      return state.set('attaching', true);

    case ATTACH_CANCEL:
    case END_ATTACH:
      return state.set('attachFlowing', false)
        .set('attachCardPosition', null)
        .set('attaching', false);

    case START_BENCH_FLOW:
      return state.set('benchFlowing', true);

    case BENCH:
      return state.set('benchPosition', action.payload.get('benchPosition'));

    case START_BENCH:
      return state.set('benching', true);

    case BENCH_CANCEL:
    case END_BENCH:
      return state.set('benchFlowing', false)
        .set('benchPosition', initialState.get('benchPosition'))
        .set('benching', false);

    case PUT_ACTIVE_POKEMON:
      return state.set('puttingActive', true);

    case END_PUT_BENCH_AS_ACTIVE_POKEMON:
      return state.set('puttingActive', false);

    case SET_ACTIVE_BENCH:
      return state.set('activeBenchPosition', action.payload.get('benchPosition'));

    case UNSET_ACTIVE_BENCH:
      return state.set('activeBenchPosition', null);

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
