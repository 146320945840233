export const SET_CONTEXT_MENU = 'ACTIVE_GAME/SET_CONTEXT_MENU';
export const GO_BACK = 'ACTIVE_GAME/GO_BACK';

export const SET_DECK = 'ACTIVE_GAME/SET_DECK';
export const SET_DECK_DONE = 'ACTIVE_GAME/SET_DECK_DONE';

export const GET_GAME = 'ACTIVE_GAME/GET_GAME';
export const SET_GAME = 'ACTIVE_GAME/SET_GAME';

export const SET_VERSION = 'ACTIVE_GAME/SET_VERSION';

export const SET_ACTIVE_POKEMON = 'ACTIVE_GAME/SET_ACTIVE_POKEMON';
export const SET_BENCHES = 'ACTIVE_GAME/SET_BENCHES';
export const SET_DECK_PILES = 'ACTIVE_GAME/SET_DECK_PILES';
export const SET_DISCARD_PILES = 'ACTIVE_GAME/SET_DISCARD_PILES';
export const SET_GAME_TURN = 'ACTIVE_GAME/SET_GAME_TURN';
export const SET_GAME_PLAYERS = 'ACTIVE_GAME/SET_GAME_PLAYERS';
export const SET_HANDS = 'ACTIVE_GAME/SET_HANDS';
export const SET_PRIZE_PILES = 'ACTIVE_GAME/SET_PRIZE_PILES';
export const SET_USERS = 'ACTIVE_GAME/SET_USERS';
