import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { attach, attachCancel } from '../Pokemon/actions';
import { selectAttachFlowing, selectAttaching } from '../Pokemon/selectors';
import { selectActivePokemon, selectIsActivePlayer, } from '../ActiveGame/selectors';
import ActionableButton from '../ActionableButton';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { Root } from './styled';
import { ACTIVE } from '../ActiveGame/contextMenuTypes';
import { setContextMenu } from '../ActiveGame/actions';

const ActivePokemon = (
  {
    activePokemon,
    attachAction,
    attachCancelAction,
    attachFlowing,
    attaching,
    isActivePlayer,
    setContextMenuAction,
    userId,
  }
) => {
  if (!activePokemon) {
    return null;
  }

  let selectActiveAction = () => {
    setContextMenuAction(ACTIVE, userId);
  };
  let onActiveCancel = null;

  if (isActivePlayer && attachFlowing) {
    selectActiveAction = attachAction;
    onActiveCancel = attachCancelAction;
  }

  return (
    <Root>
      <ActionableButton
        onClick={selectActiveAction}
        onCancel={onActiveCancel}
        disabled={attaching}
        plain
      >
        <Image image={cardImageUrl(activePokemon.get('card'))} />
      </ActionableButton>
    </Root>
  );
};

ActivePokemon.propTypes = {
  userId: PropTypes.number.isRequired, // Passed in

  attachFlowing: PropTypes.bool,
  attaching: PropTypes.bool,
  activePokemon: PropTypes.instanceOf(Map),
  isActivePlayer: PropTypes.bool,

  attachAction: PropTypes.func,
  attachCancelAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    attachFlowing: selectAttachFlowing,
    attaching: selectAttaching,
    activePokemon: selectActivePokemon,
    isActivePlayer: selectIsActivePlayer,
  }),
  {
    attachAction: attach,
    attachCancelAction: attachCancel,
    setContextMenuAction: setContextMenu,
  }
)(ActivePokemon);
