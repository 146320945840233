import React, { Fragment, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Slider from 'react-slick';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { LargeSlide, LargeSlider, SmallSlide, SmallSlider } from './styled';

const slidesToShow = 6;

const SliderView = ({ cards, getSelectButton, getDim, viewingCard, activeCardIndex, onChange }) => {

  const mainRef = useRef(null);

  const [mainNav, setMainNav] = useState(null);


  useEffect(function () {
    if (mainNav && mainNav.slickGoTo) {
      mainNav.slickGoTo(activeCardIndex);
    }
  }, [activeCardIndex, mainNav]);

  useEffect(function () {
    setMainNav(mainRef.current);
  }, []);

  const firstCard = cards.first();

  const largeSlides = cards.map((card, i) => (
    <div key={i}>
      <LargeSlide>
        <Image
          minSize
          ratio={.5}
          image={cardImageUrl(card)}
          dim={getDim && getDim(card)}
        />
        {getSelectButton && getSelectButton(card)}
      </LargeSlide>
    </div>
  ));

  const smallSlides = cards.map((card, i) => (
    <div key={i} onClick={() => onChange(i)}>
      <SmallSlide
        isFirstCard={card.get('pivot_id') === firstCard.get('pivot_id')}
        slidesToShow={slidesToShow}
      >
        <Image
          image={cardImageUrl(card)}
          dim={getDim && getDim(card)}
        />
      </SmallSlide>
    </div>
  ));

  return (
    <Fragment>
      <LargeSlider viewingCard={viewingCard}>
        <Slider
          ref={mainRef}
          speed={200}
          infinite
          arrows
        >
          {largeSlides}
        </Slider>
      </LargeSlider>
      <SmallSlider>
        {cards.size >= 6 ?
          <Slider
            slidesToShow={slidesToShow}
            swipeToSlide
            infinite
            arrows
          >
            {smallSlides}
          </Slider> :
          <div style={{ display: 'flex' }}>{smallSlides}</div>
        }
      </SmallSlider>
    </Fragment>
  );
};

SliderView.propTypes = {
  cards: PropTypes.instanceOf(List).isRequired,
  getSelectButton: PropTypes.func,
  getDim: PropTypes.func,
  viewingCard: PropTypes.bool,
  activeCardIndex: PropTypes.number,
  onChange: PropTypes.func,
};

export default SliderView;