import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectActiveGame, selectOpponent, selectPlayer } from '../ActiveGame/selectors';
import { setFirstPlayer } from './actions';
import { selectSettingFirstPlayer } from './selectors';

const SelectStartPlayer = ({ game, player, opponent, setFirstPlayerAction, settingFirstPlayer }) => {
  const [activePlayerId, setActivePlayerId] = useState(game.get('first_player_id'));

  useEffect(() => {
    setActivePlayerId(game.get('first_player_id'));
  }, [game]);

  if (game.get('active_player_id') || !game.get('start_chooser_id')) {
    return null;
  }

  const isStartChooser = game.get('start_chooser_id') === player.getIn(['user', 'id']);

  return (
    <form
      onSubmit={(e) => e.preventDefault() || setFirstPlayerAction(activePlayerId)}
      style={{ display: 'inline-block' }}
    >
      {isStartChooser ?
        <span>Select Start Player: </span> :
        <span>Awaiting opponent to select start player.</span>}
      <label>
        {player.getIn(['user', 'name'])}
        <input
          type="radio"
          onChange={() => setActivePlayerId(player.getIn(['user', 'id']))}
          checked={player.getIn(['user', 'id']) === activePlayerId}
          disabled={settingFirstPlayer || !isStartChooser}
        />
      </label>
      <label>
        {opponent.getIn(['user', 'name'])}
        <input
          type="radio"
          onChange={() => setActivePlayerId(opponent.getIn(['user', 'id']))}
          checked={opponent.getIn(['user', 'id']) === activePlayerId}
          disabled={settingFirstPlayer || !isStartChooser}
        />
      </label>
      <button disabled={settingFirstPlayer || !isStartChooser}>Set First Player</button>
    </form>
  );
};

SelectStartPlayer.propTypes = {
  game: PropTypes.instanceOf(Map).isRequired,
  player: PropTypes.instanceOf(Map).isRequired,
  opponent: PropTypes.instanceOf(Map).isRequired,
  setFirstPlayerAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    game: selectActiveGame,
    player: selectPlayer,
    opponent: selectOpponent,
    settingFirstPlayer: selectSettingFirstPlayer,
  }),
  {
    setFirstPlayerAction: setFirstPlayer,
  },
)(SelectStartPlayer);
