import { call, put, takeLatest } from 'redux-saga/effects'
import { loginRunning, loginSuccess, loginFailure } from './actions';
import { GET_CURRENT_USER, LOGIN, LOGOUT } from './constants';
import history from '../history'
import cookieManager from '../services/cookieManager';
import { getAccessToken, findCurrentUser } from '../services/authenticate';
import { setAxiosAccessToken } from '../services/helpers';
import { appLoadedSuccess } from '../App/actions';

function* loginTask(action) {
  const email = action.payload.get('email');
  const password = action.payload.get('password');

  try {
    const { access_token: accessToken, expires_in: expiresIn } = yield call(getAccessToken, email, password);

    yield call(cookieManager.setAccessToken, accessToken, expiresIn);

    yield call(setAxiosAccessToken);

    const user = yield call(findCurrentUser);
    yield put(loginSuccess(user, accessToken));
  } catch (e) {
    console.error(e);
    let message = e.message;
    // response exists on an axios response. data.message comes from the invalid credential response.
    if (e.response && e.response.data && e.response.data.message) {
      message = e.response.data.message;
    }
    yield put(loginFailure(message));
  }
}

function* logoutTask() {
  yield call(cookieManager.removeAccessToken);
  yield call(setAxiosAccessToken);
  yield call(history.push, '/');
}

function* getCurrentUserTask() {
  yield put(loginRunning());

  const accessToken = yield call(cookieManager.getAccessToken);
  const user = yield call(findCurrentUser);
  yield put(loginSuccess(user, accessToken));

  yield put(appLoadedSuccess());
}

function* getCurrentUserWatcher() {
  yield takeLatest(GET_CURRENT_USER, getCurrentUserTask);
}

function* loginWatcher() {
  yield takeLatest(LOGIN, loginTask);
}

function* logoutWatcher() {
  yield takeLatest(LOGOUT, logoutTask);
}

export default [
  getCurrentUserWatcher,
  loginWatcher,
  logoutWatcher,
];
