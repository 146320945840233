import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { selectDeckPile, selectGameTurn } from '../ActiveGame/selectors';
import { draw } from './actions';
import { Root } from './styled';
import { Image } from '../Card/styled';
import { cardImageUrl } from '../services/helpers';
import { DECK } from '../ActiveGame/contextMenuTypes';
import { setContextMenu } from '../ActiveGame/actions';
import ActionableButton from '../ActionableButton';

const Deck = ({ deckPile, gameTurn, setContextMenuAction, userId }) => {
  if (!deckPile || !gameTurn) {
    return null;
  }
  return (
    <Root>
      <ActionableButton onClick={() => setContextMenuAction(DECK, userId)} plain>
        <Image image={cardImageUrl()} />
      </ActionableButton>
    </Root>
  );
};

Deck.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  deckPile: PropTypes.instanceOf(Map),
  gameTurn: PropTypes.instanceOf(Map),
  drawAction: PropTypes.func,
};

export default connect(
  createStructuredSelector({
    deckPile: selectDeckPile,
    gameTurn: selectGameTurn,
  }),
  {
    drawAction: draw,
    setContextMenuAction: setContextMenu,
  }
)(Deck);
