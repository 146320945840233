import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { useParams } from 'react-router-dom';
import { getGame, setGame } from './actions';
import { selectActiveGame, selectOpponent, selectPlayer } from './selectors';
import { GameContainer, GameMap } from './styled';
import MainContextMenu from './MainContextMenu';
import Card from '../Card';
import Hand from '../Hand';
import Player from '../Player';
import ActivePokemonMenu from '../ActivePokemon/ActivePokemonMenu';
import BenchContextMenu from '../Bench/BenchContextMenu';
import PrizesContextMenu from '../Prizes/PrizesContextMenu';
import DeckContextMenu from '../Deck/DeckContextMenu';
import DiscardPileContextMenu from '../DiscardPile/DiscardPileContextMenu';
import RetreatContextMenu from '../RetreatButton/RetreatContextMenu';

const Game = ({ getGameAction, setGameAction, game, player, opponent }) => {
  const { id } = useParams();

  useEffect(() => {
    if (!game) {
      setGameAction(null);
      getGameAction(id);
    }
  }, [setGameAction, getGameAction, id, game]);

  if (!game) {
    return 'loading game...';
  }

  return (
    <div>
      <h2>Game</h2>

      <GameContainer>
        <GameMap
          isFirstPlayer={game.get('first_player_id') === null ||
          game.get('first_player_id') === player.getIn(['user', 'id'])}
        />
        {player.getIn(['deckPile', 'deck_id']) && <Player user={player.get('user')} />}
        {opponent.getIn(['deckPile', 'deck_id']) && <Player user={opponent.get('user')} opponent />}
      </GameContainer>

      <MainContextMenu userId={player.getIn(['user', 'id'])} />

      {player.getIn(['deckPile', 'deck_id']) && <Hand userId={player.getIn(['user', 'id'])} />}
      {opponent.getIn(['deckPile', 'deck_id']) && <Hand userId={opponent.getIn(['user', 'id'])} />}

      <RetreatContextMenu userId={player.getIn(['user', 'id'])} />
      {/* Show Opponents hand face down across the map */}

      <ActivePokemonMenu userId={player.getIn(['user', 'id'])} />
      <ActivePokemonMenu userId={opponent.getIn(['user', 'id'])} />

      <BenchContextMenu userId={player.getIn(['user', 'id'])} />
      <BenchContextMenu userId={opponent.getIn(['user', 'id'])} />

      <PrizesContextMenu userId={player.getIn(['user', 'id'])} />
      <PrizesContextMenu userId={opponent.getIn(['user', 'id'])} />

      <DeckContextMenu userId={player.getIn(['user', 'id'])} />
      <DeckContextMenu userId={opponent.getIn(['user', 'id'])} />

      <DiscardPileContextMenu userId={player.getIn(['user', 'id'])} />
      <DiscardPileContextMenu userId={opponent.getIn(['user', 'id'])} />

      <Card />
    </div>
  );
};

Game.propTypes = {
  game: PropTypes.instanceOf(Map),
  player: PropTypes.object.isRequired,
  opponent: PropTypes.object.isRequired,
  getGameAction: PropTypes.func.isRequired,
  setGameAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    game: selectActiveGame,
    player: selectPlayer,
    opponent: selectOpponent,
  }),
  {
    getGameAction: getGame,
    setGameAction: setGame,
  }
)(Game);