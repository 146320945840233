import { createSelector } from 'reselect';

const selectDomain = (state) => state.pokemon;

export const selectAttaching = createSelector(
  [selectDomain], (state) => state.get('attaching'),
);

export const selectAttachCardPosition = createSelector(
  [selectDomain], (state) => state.get('attachCardPosition'),
);

export const selectAttachFlowing = createSelector(
  [selectDomain], (state) => state.get('attachFlowing'),
);

export const selectBenchPosition = createSelector(
  [selectDomain], (state) => state.get('benchPosition'),
);

export const selectBenchFlowing = createSelector(
  [selectDomain], (state) => state.get('benchFlowing'),
);

export const selectBenching = createSelector(
  [selectDomain], (state) => state.get('benching'),
);

export const selectPuttingActive = createSelector(
  [selectDomain], (state) => state.get('puttingActive'),
);

export const selectActiveBenchPosition = createSelector(
  [selectDomain], (state) => state.get('activeBenchPosition'),
);
