import axios from 'axios';
import { API_URL } from '../env';
import { normalize } from './helpers';

const activePokemon = {
    attack: async (gameId, moveId) => {
      const response = await axios.patch(`${API_URL}/games/${gameId}/attack`, {
        move_id: moveId,
      });

      return normalize(response.data);
    }
};

export default activePokemon;
