import { fromJS } from 'immutable';
import { APP_LOADED_SUCCESS, CLEAR_ERRORS, ERROR, SET_SIZE } from './constants';

const initialState = fromJS({
  appReady: false,
  width: null,
  height: null,
  errors: [],
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case APP_LOADED_SUCCESS:
      return state.set('appLoaded', true);

    case SET_SIZE:
      return state.set('width', action.payload.get('width'))
        .set('height', action.payload.get('height'));

    case ERROR:
      return state.set('errors', state.get('errors').push(action.payload.get('error')));

    case CLEAR_ERRORS:
      return state.set('errors', initialState.get('errors'));

    default:
      return state;
  }
}

export default reducer;
