import { fromJS } from 'immutable';
import { START_BENCH_FLOW } from '../Pokemon/constants';
import { APP_RESET } from '../App/constants';

const initialState = fromJS({
  handPosition: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case START_BENCH_FLOW:
      return state.set('handPosition', action.payload.get('handPosition'));

    case APP_RESET:
      return initialState;

    default:
      return state;
  }
}

export default reducer;
