import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import {
  selectActiveGame,
  selectGameIsStarted,
  selectGamePlayer,
  selectGameTurn,
  selectIsActivePlayer,
} from '../ActiveGame/selectors';
import DrawButton from '../DrawButton';
import PassButton from '../PassButton';
import { setContextMenu } from '../ActiveGame/actions';
import { HAND } from '../ActiveGame/contextMenuTypes';

const ActionChooser = ({ gameIsStarted, isActivePlayer, gamePlayer, gameTurn, userId, setContextMenuAction }) => (
  <Fragment>
    {gameIsStarted && isActivePlayer && <Fragment>
      {!gameTurn.get('drew') && <DrawButton userId={userId} />}
      {gameTurn.get('drew') && !gameTurn.get('attack_started') && <ul>
        <li>Play Trainer</li>
        {!gameTurn.get('attached') && <li>Attach Energy (once)</li>}
        <li>Place Bench Pokémon</li>
        <li>Evolve Pokémon</li>
        <li>Use Power</li>
        <li>Retreat</li>
        <li>Put Pokémon on Bench</li>
        <li>End Turn: Attack or <PassButton userId={userId} /></li>
      </ul>}
      {gameTurn.get('attack_started') && !gameTurn.get('attack_damage') && <div>
        Attack Effects Pending
      </div>}
      {false && gameTurn.get('attack_ended') && <div>
        Attack Final Effects Pending
      </div>}
      {gameTurn.get('attack_damage') && gamePlayer.get('prizes') > 0 && <div>
        Draw your {gamePlayer.get('prizes') === 1 ? 'prize' : `${gamePlayer.get('prizes')} prizes`}.
      </div>}
    </Fragment>}
    {gameIsStarted && !isActivePlayer && <Fragment>
      Not your turn.
    </Fragment>}
    <button onClick={() => setContextMenuAction(HAND, userId)}>View Hand</button>
  </Fragment>
);

ActionChooser.propTypes = {
  game: PropTypes.instanceOf(Map).isRequired,
  gameTurn: PropTypes.instanceOf(Map).isRequired,
  gameIsStarted: PropTypes.bool.isRequired,
  isActivePlayer: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

export default connect(
  createStructuredSelector({
    game: selectActiveGame,
    gamePlayer: selectGamePlayer,
    gameTurn: selectGameTurn,
    gameIsStarted: selectGameIsStarted,
    isActivePlayer: selectIsActivePlayer,
  }), {
    setContextMenuAction: setContextMenu,
  }
)(ActionChooser);
