import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import ContextMenu from '../ContextMenu';
import DrawButton from '../DrawButton';
import { DECK } from '../ActiveGame/contextMenuTypes';
import { selectDeckPile } from '../ActiveGame/selectors';

const DeckContextMenu = ({ deckPile, userId }) => {
  if (!deckPile) {
    return null;
  }
  return (
    <ContextMenu menu={DECK} userId={userId}>
      Deck Pile Count: {deckPile.get('count')}
      <DrawButton userId={userId} />
    </ContextMenu>
  );
}

DeckContextMenu.propTypes = {
  userId: PropTypes.number.isRequired, // passed in
  deckPile: PropTypes.instanceOf(Map),
};

export default connect(
  createStructuredSelector({
    deckPile: selectDeckPile,
  })
)(DeckContextMenu);
