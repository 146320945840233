import { fromJS } from 'immutable';
import { LOGIN, LOGIN_FAILURE, LOGIN_RUNNING, LOGIN_SUCCESS, LOGOUT } from './constants';

const initialState = fromJS({
  accessToken: null,
  loggingIn: false,
  error: null,
  user: null,
});

function reducer(state = initialState, action) {
  switch (action.type) {

    case LOGIN:
    case LOGIN_RUNNING:
      return state.set('loggingIn', true) // show UI of loggingIn
        .set('error', null);

    case LOGIN_SUCCESS:
      return state.set('accessToken', action.payload.get('accessToken'))
        .set('user', action.payload.get('user'))
        .set('loggingIn', false);

    case LOGIN_FAILURE:
      return state.set('loggingIn', false).set('error', action.payload.get('error'));

    case LOGOUT:
      return state.set('accessToken', initialState.accessToken);

    default:
      return state;
  }
}

export default reducer;
