import activeGameSagas from './ActiveGame/sagas';
import { all, spawn, call, put } from 'redux-saga/effects';
import { logout } from './Login/actions';
import { appLoadedSuccess, error } from './App/actions';
import appSagas from './App/sagas';
import attackButtonSagas from './AttackButton/sagas';
import deckSagas from './Deck/sagas';
import decksSagas from './Decks/sagas';
import gameStartSagas from './GameStart/sagas';
import gamesSagas from './Games/sagas';
import loginSagas from './Login/sagas';
import passButtonSagas from './PassButton/sagas';
import pokemonSagas from './Pokemon/sagas';
import prizesSagas from './Prizes/sagas';
import retreatButtonSagas from './RetreatButton/sagas';
import usersSagas from './Users/sagas';

const sagaGroups = [
  activeGameSagas,
  attackButtonSagas,
  appSagas,
  deckSagas,
  decksSagas,
  gameStartSagas,
  gamesSagas,
  loginSagas,
  passButtonSagas,
  pokemonSagas,
  prizesSagas,
  retreatButtonSagas,
  usersSagas,
];


/**
 * Fork saga that will never die.
 *
 * @param saga
 */
function makeSpawn(saga) {
  return spawn(function* () {
    while (true) {
      try {
        yield call(saga);
        break;
      } catch (e) {
        if (e.response && e.response.status === 401) {
          console.error(e.response.statusText);
          yield put(logout());
          yield put(appLoadedSuccess()); // load success for when token is invalid so that the app is loaded
        } else {
          yield put(error(e));
        }
      }
    }
  });
}

function* rootSaga() {
  const spawns = [];

  sagaGroups.forEach(
    sagas => sagas.map(
      saga => spawns.push(makeSpawn(saga))
    )
  );

  yield all(spawns);
}

export default rootSaga;
