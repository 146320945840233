import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectHeight, selectWidth } from '../App/selectors';

export const connectedStyle = connect(
  createStructuredSelector({
      windowWidth: selectWidth,
      windowHeight: selectHeight,
    }
  ),
  null,
  null,
  { forwardRef: true }
);
