import { createSelector } from 'reselect';

const selectDomain = (state) => state.decks;

export const selectDecks = createSelector(
  [selectDomain], (state) => state.get('decks').sortBy(user => user.get('name')),
);

export const selectDecksLoaded = createSelector(
  [selectDomain], (state) => state.get('decksLoaded')
);
