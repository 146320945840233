import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Root = styled.div`
  ${props => props.opponent ? 'transform: rotate(180deg);' : ''}
`;

Root.propTypes = {
  opponent: PropTypes.bool,
};

export {
  Root
};
