import { createSelector } from 'reselect';

const selectDomain = (state) => state.games;

export const selectGames = createSelector(
  [selectDomain], (state) => state.get('games'),
);

export const selectCreating = createSelector(
  [selectDomain], (state) => state.get('creating'),
);