import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';
import { useHistory } from 'react-router-dom';
import { getGames, deleteGame } from './actions';
import { selectGames } from './selectors';
import CreateGame from './CreateGame';

const Games = ({ getGamesAction, deleteGameAction, games }) => {
  const history = useHistory();

  useEffect(() => {
    getGamesAction();
  }, [getGamesAction]);

  return (
    <div>
      <h2>Games</h2>
      <CreateGame />
      <div>
        {games.map((game) => (
          <p key={game.get('id')}>
            {game.get('id')}
            <button onClick={() => history.push(`/games/${game.get('id')}`)}>Open</button>
            <button onClick={() => deleteGameAction(game.get('id'))}>Delete</button>
          </p>
        ))}
      </div>
    </div>
  );
};

Games.propTypes = {
  games: PropTypes.instanceOf(List).isRequired,
  deleteGameAction: PropTypes.func.isRequired,
  getGamesAction: PropTypes.func.isRequired,
};

export default connect(
  createStructuredSelector({
    games: selectGames,
  }),
  {
    getGamesAction: getGames,
    deleteGameAction: deleteGame,
  }
)(Games);
