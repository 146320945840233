import { createSelector } from 'reselect';
import { selectGameTurn, selectIsActivePlayer } from '../ActiveGame/selectors';

const selectDomain = (state) => state.passButton;

export const selectCanPass = createSelector(
  [selectIsActivePlayer, selectGameTurn],
  (isActivePlayer, gameTurn) => isActivePlayer && gameTurn.get('drew') && !gameTurn.get('attack_started')
);

export const selectPassing = createSelector(
  [selectDomain], (state) => state.get('passing'),
);