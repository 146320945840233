import { call, put, takeLatest } from 'redux-saga/effects'
import { CREATE_GAME, DELETE_GAME, GET_GAMES } from './constants';
import { createGameSuccess, getGames, setGames } from './actions';
import { all, create, destroy } from '../services/game';

function* createGameTask(action) {
  const opponentId = action.payload.get('opponentId');
  yield call(create, opponentId);
  yield put(getGames());
  yield put(createGameSuccess());
}

function* createGameWatcher() {
  yield takeLatest(CREATE_GAME, createGameTask);
}

function* deleteGameTask(action) {
  const gameId = action.payload.get('gameId');
  yield call(destroy, gameId);
}

function* deleteGameWatcher() {
  yield takeLatest(DELETE_GAME, deleteGameTask);
}

function* getGamesTask() {
  const games = yield call(all);
  yield put(setGames(games))
}

function* getGamesWatcher() {
  yield takeLatest(GET_GAMES, getGamesTask);
}

export default [
  createGameWatcher,
  deleteGameWatcher,
  getGamesWatcher,
];
